
interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    confirmButtonTitle: string;
    onConfirmClick: () => void;
    children: React.ReactNode;
}

export const GiscModal = (props: ModalProps) => {
    const onConfirmButtonClick = () => {
        props.onConfirmClick();
        // props.onClose();
    };

    return (
      <>
        {props.isOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={() => props.onClose()}>
            <div className="bg-white w-1/2 rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()}>
              <h2 className="text-2xl font-bold p-4 upt-blue-bg text-white font-semibold rounded-t-lg">{props.title}</h2>
              <p className="mb-4 p-6">
                {props.children}
              </p>

              <div className="flex justify-between">
                <button
                        onClick={props.onClose}
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300 m-4"
                    >
                        Anulare
                    </button>
                    <button className="upt-blue-bg text-white px-4 py-2 rounded hover:bg-indigo-500 transition duration-300 m-4 font-medium" onClick={() => onConfirmButtonClick()}>
                        {props.confirmButtonTitle}
                    </button>
                </div>
            </div>
          </div>
        )}
      </>
    );
  };