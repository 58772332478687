import { LocGeografic } from "../models/loc-geografic.model";
import { Universitate } from "../models/universitate.model";
import { SheetPage } from "./SheetPage";
import { GetLocuriGeograficeWithFilters, GetUniversitati, GetValidationRules, HandleChanges } from "../api/ApiService";
import { ValidationRule } from "../models/validation-rule.model";
import { useEffect, useState } from "react";
import { Column, SelectColumn, textEditor } from "react-data-grid";
import { DropdownOption, RenderDropdown } from "../components/renderers/DropdownRenderer";
import { HeaderCellRenderer } from "../components/renderers/HeaderCellRenderer";
import { PermissionType } from "../store/permission-context";


export const LocuriGeograficePage = () => {
  const [locuriGeografice, setLocuriGeografice] = useState<LocGeografic[]>([]);
  const [universitati, setUniversitati] = useState<Universitate[]>([]);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [filters, setFilters] = useState<{ filterName: string, filterValue: string }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetRequiredData().then(x => {
      setLocuriGeografice(x.locuriGeografice);
      setUniversitati(x.universitati);
      setValidationRules(x.validationRules);
      setLoading(false);
    })

  }, [filters]);

  const GetRequiredData = async (): Promise<{ locuriGeografice: LocGeografic[], universitati: Universitate[], validationRules: ValidationRule[] }> => {
    let locuriGeografice = (await GetLocuriGeograficeWithFilters(filters)).data;

    let universitati = (await GetUniversitati()).data;

    let validationRules = [];
    try {
      validationRules = (await GetValidationRules('LocGeografic')).data;
    } catch (error) {
      console.log('Validation rules not found');
    }

    return { locuriGeografice, universitati, validationRules }
  }

  const applyFilter = (filterName: string, filterValue: string) => {
    setFilters(oldFilters => [...oldFilters.filter(f => f.filterName !== filterName && f.filterValue !== ''), { filterName, filterValue }]);
  }

  const getColumns = (): Column<LocGeografic>[] => {
    return [
      SelectColumn,
      {
        key: 'nume',
        name: 'Nume',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ' '}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'universitate',
        name: 'Universitate',
        renderEditCell: (p) => {
          return <RenderDropdown {...p} options={universitati.map((d) => ({ label: d.nume, value: d.id }))} items={universitati} handleChange={handleUniversitateDropdownChange} foreignKeyItemKey='univId' />
        },
        renderCell: (p) => (<>{p.row.universitate?.nume}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === 'universitate')?.filterValue || ' '}
            applyFilter={val => applyFilter('universitate', val)}
          />)
      }
    ]
  };

  const handleUniversitateDropdownChange = (row: LocGeografic, columnKey: string, selected: DropdownOption | null, items: Universitate[]) => {
    if (selected) {
      const found = items.find(i => i.id === selected.value);
      if (!found) {
        row['univId'] = '';
        delete row['universitate'];
      }
      else {
        row['univId'] = found.id;
        row['universitate'] = found;
      }
    }
    else {
      row['univId'] = '';
      delete row['universitate'];
    }

    return row;
  }

  const rowsToString = (rows: LocGeografic[], columnsOrder: readonly number[]): string => {
    var copiedString = '';
    const columnsKeys = getColumns().map(c => c.key).slice(1);

    rows.forEach(row => {
      for (let idx = 0; idx < columnsKeys.length; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        var separator = idx == columnsKeys.length - 1 ? '\r\n' : '\t';

        switch (columnKey) {
          case 'universitate':
            var universitate = universitati.find(e => e.id == row.universitate?.id);
            copiedString += universitate?.nume;
            break;
          default:
            copiedString += row[columnKey as keyof LocGeografic];
            break;
        }
        copiedString += separator;
      }
    })

    return copiedString;
  }

  const stringToRows = (splitText: string[][], columnsOrder: readonly number[]): LocGeografic[] => {
    var rows: LocGeografic[] = [];
    const columnsKeys = getColumns().map(c => c.key).slice(1);

    splitText.forEach(textRow => {
      var row: LocGeografic = structuredClone(defaultObject) as LocGeografic;
      row.id = crypto.randomUUID();
      for (let idx = 0; idx < columnsKeys.length; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'universitate':
            var universitate = universitati.find(e => e.nume == textRow[idx]);
            row['univId'] = universitate?.id ?? "";
            row['universitate'] = universitate;
            break;
          default:
            row[columnKey] = textRow[idx];
            break;
        }
      }
      rows.push(row);
    })

    return rows;
  }

  const addPastedRows = (currentRows: LocGeografic[], rowIdx: number, columnIdx: number, columnsOrder: readonly number[], splitText: string[][]): { rows: LocGeografic[], indexes: number[] } => {
    var initialRowCount = currentRows.length;
    var currentSheetRowIdx = rowIdx;
    const columnsKeys = columns.map(c => c.key).slice(1);

    const transformedClipboardRows = stringToRows(splitText, columnsOrder);

    var newSheetRows = [...currentRows];
    splitText.forEach((clipboardRow, clipboardRowIdx) => {
      var clipboardColumnIdx = 0;

      var row = structuredClone(defaultObject) as LocGeografic;
      row.id = crypto.randomUUID();
      if (currentSheetRowIdx < initialRowCount - 1) {
        row = currentRows[currentSheetRowIdx] as LocGeografic;
      }

      for (let idx = columnIdx - 1; idx < columnsOrder.length; idx++) {
        if (clipboardColumnIdx == clipboardRow.length) { //if clipboard row has reached the last column
          break;
        }

        var columnKey: string = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'universitate':
            row['univId'] = transformedClipboardRows[clipboardRowIdx]['universitate']?.id || '';
            break;
          default:
            break;
        }
        row[columnKey] = transformedClipboardRows[clipboardRowIdx][columnKey];
      }

      if (currentSheetRowIdx <= initialRowCount) { //if we have not reached the end of the sheet, we edit the current row, else add a new one
        newSheetRows[currentSheetRowIdx++] = row;
      }
      else {
        newSheetRows.push(row);
      }
    })

    const indexes = [...Array(transformedClipboardRows.length).keys()].map(i => i + rowIdx);

    return { rows: newSheetRows, indexes: indexes };
  }

  const deleteCell = (currentRows: LocGeografic[], rowIdx: number, columnKey: string): { rows: LocGeografic[], deleted: boolean } => {
    var deleted = true;

    switch (columnKey) {
      case 'universitate':
        currentRows[rowIdx]['univId'] = '';
        delete currentRows[rowIdx]['universitate'];
        break;
      default:
        deleted = false;
        break;
    }

    return { rows: currentRows, deleted: deleted };

  }

  // to detect empty objects or deleted objects
  const hasEmptyProperties = (row: LocGeografic): boolean => {
    if (row.univId !== '' || !!row.univId) return false;
    if (row.nume !== '' || !!row.nume) return false;
    return true;
  }

  const refreshTable = (): void => {
    setFilters([...filters]);
  }

  const columns = getColumns();
  let defaultObject: LocGeografic = { id: '', nume: '', univId: '' };

  if (loading) {
    return <div>Loading...</div>;
  }

  return <SheetPage
    requiredEditPermission={PermissionType.LocGeograficWrite}
    sheetColumns={columns}
    title={'Locuri geografice'}
    hasEmptyProperties={e => hasEmptyProperties(e as LocGeografic)}
    initialEntries={locuriGeografice}
    validationRules={validationRules}
    onSave={(modifiedEntries, deletedEntries) => HandleChanges(modifiedEntries, deletedEntries, 'loc-geografic')}
    rowsToString={(rows, columnsOrder) => rowsToString(rows as LocGeografic[], columnsOrder)}
    stringToRows={(splitText, columnsOrder) => stringToRows(splitText, columnsOrder)}
    addPastedRows={(currentRows, rowIdx, columnIdx, columnsOrder, splitText) => addPastedRows(currentRows, rowIdx, columnIdx, columnsOrder, splitText)}
    deleteCell={(currentRows, rowIdx, columnKey) => deleteCell(currentRows, rowIdx, columnKey)}
    defaultObject={defaultObject}
    refreshTable={refreshTable} />
}