import { PlanInvatamantTableData } from "../../models/plan-invatamant-table-data.model";
import { PlanSummaryEntryModel } from "../../models/plan-summary-entry.model";
import { GenericReadonlyTable, GenericTableProps } from "../GenericReadonlyTable";

export interface PlanSummaryProps {
    planData: PlanInvatamantTableData;
}

const ani = [
    'I', 'II', 'III', 'IV', 'V', 'VI'
];

export const PlanSummary = (props: PlanSummaryProps) => {

    const planSummary: PlanSummaryEntryModel[] = props.planData.summary;console.log(planSummary);
    const crediteTotal = (planSummary.find(e => e.slug === "credite-total")?.value || 0);
    const crediteDisciplineOptionale = (planSummary.find(e => e.slug === "credite-optionale-total")?.value || 0);
    const creditePractica = 0; //TODO inlocuieste cu valoare
    const crediteElaborareProiectDiploma = 0; //TODO inlocuieste cu valoare
    const crediteExamenDiploma = 0; //TODO inlocuieste cu valoare
    const crediteDisciplineEducatieFizica = 0; //TODO inlocuieste cu valoare

    // credite
    const crediteTable: GenericTableProps = {
        headers: [{ label: 'Credite', key: 'credite' }, { label: 'Număr credite', key: 'numar' }, { label: 'Criterii', key: 'criteriu' }],
        data: [
            { credite: 'Numărul de credite alocate conform legislației', numar: crediteTotal, criteriu: '240 (exclusiv creditele pentru promovarea examenului de diplomă)' },
            { credite: 'Credite pentru disciplinele obligatorii', numar: 250, criteriu: 'DEV: lipsesc creditele de pe examen' },
            { credite: 'Credite pentru disciplinele opționale', numar: crediteDisciplineOptionale, criteriu: '' },
            { credite: 'Credite la practică (în cadrul disciplinelor obligatorii)', numar: '??', criteriu: 'min 4 credite DEV: lipsesc creditele' },
            { credite: 'Credite pentru elaborarea proiectului de diplomă', numar: '??', criteriu: 'min 4 credite DEV: lipsesc creditele' },
            { credite: 'Credite pentru promovarea examenului de diplomă', numar: '??', criteriu: '10 credite DEV: lipsesc creditele' },
            { credite: 'Credite pentru disciplinele de Educație Fizică', numar: '??', criteriu: 'DEV: lipsesc creditele' },
        ]
    }

    const creditePeSemestru: GenericTableProps = {
        headers: [{ label: 'Anul', key: 'an' }, { label: 'sem. I', key: 'sem-1' }, { label: 'sem. 2', key: 'sem-2' }, { label: 'Total', key: 'total' }],
        data: []
    }

    props.planData.years.forEach((year, yearIdx) => {
        const crediteSem1 = (year[0].summary.find(e => e.slug === "credite-semestru")?.value || 0);
        const crediteSem2 = (year[1].summary.find(e => e.slug === "credite-semestru")?.value || 0);
        creditePeSemestru.data.push({ an: `Anul ${ani[yearIdx]}`, 'sem-1': crediteSem1, 'sem-2': crediteSem2, total: crediteSem1 + crediteSem2 });
    })



    const raportOreCredite: GenericTableProps = {
        headers: [{ label: 'Anul', key: 'an' }, { label: 'sem. I', key: 'sem-1' }, { label: 'sem. 2', key: 'sem-2' }],
        data: [
            { an: 'Anul I', 'sem-1': 'DA', 'sem-2': 'DA' },
            { an: 'Anul II', 'sem-1': 'DA', 'sem-2': 'DA' },
            { an: 'Anul III', 'sem-1': 'DA', 'sem-2': 'DA' },
            { an: 'Anul IV', 'sem-1': 'DA', 'sem-2': 'DA' }
        ]
    }

    const oreCreditePeSemestru: GenericTableProps = {
        headers: [{ label: 'Anul', key: 'an' }, { label: 'sem. I', key: 'sem-1' }, { label: 'sem. 2', key: 'sem-2' }],
        data: [
            { an: 'Anul I', 'sem-1': 25, 'sem-2': 25 },
            { an: 'Anul II', 'sem-1': 25, 'sem-2': 25 },
            { an: 'Anul III', 'sem-1': 25, 'sem-2': 25 },
            { an: 'Anul IV', 'sem-1': 25, 'sem-2': 25 }
        ]
    }
    // end credite

    // structura an

    const structuraAnUniversitar: GenericTableProps = {
        headers: [
            { label: 'Anul', key: 'an' },
            {
                label: 'Activități didactice', key: '',
                subHeaders: [
                    { label: 'sem. I', key: 'sem-1' },
                    { label: 'sem. 2', key: 'sem-2' }
                ]
            },
            {
                label: 'Sesiuni', key: '',
                subHeaders: [
                    { label: 'Iarnă', key: 'iarna' },
                    { label: 'Restanțe iarnă', key: 'restante-iarna' },
                    { label: 'Vară', key: 'vara' },
                    { label: 'Restanță vară', key: 'restante-vara' },
                    { label: 'Restanță Toamnă', key: 'restante-toamna' },
                ]
            },
            { label: 'Practică*', key: 'practica' },
        ],
        data: [
            { an: 'Anul I', 'sem-1': 14, 'sem-2': 14, iarna: 3, 'restante-iarna': 1, vara: 3, 'restante-vara': 1, 'restante-toamna': 2, practica: '' },
            { an: 'Anul II', 'sem-1': 14, 'sem-2': 14, iarna: 3, 'restante-iarna': 1, vara: 3, 'restante-vara': 1, 'restante-toamna': 2, practica: '' },
            { an: 'Anul III', 'sem-1': 14, 'sem-2': 14, iarna: 3, 'restante-iarna': 1, vara: 3, 'restante-vara': 1, 'restante-toamna': 2, practica: 6 },
            { an: 'Anul IV', 'sem-1': 14, 'sem-2': 7, iarna: 3, 'restante-iarna': 1, vara: 3, 'restante-vara': 1, 'restante-toamna': '', practica: '' },
        ]
    }

    // end structura an


    // examinare

    const disciplineTotal = (planSummary.find(e => e.slug === "discipline-total")?.value || 0);
    const examinariExamenTotal = (planSummary.find(e => e.slug === "evaluari-examen-total")?.value || 0);
    const procentExamenTotal = (examinariExamenTotal / disciplineTotal * 100).toFixed(2);

    const examinariDistribuitaTotal = (planSummary.find(e => e.slug === "evaluari-distribuita-total")?.value || 0);
    const procentDistribuitaTotal = (examinariDistribuitaTotal / disciplineTotal * 100).toFixed(2);

    const examinariColocviuTotal = (planSummary.find(e => e.slug === "evaluari-colocviu-total")?.value || 0);
    const procentColocviuTotal = (examinariColocviuTotal / disciplineTotal * 100).toFixed(2);

    const examinareTable: GenericTableProps = {
        headers: [
            { label: 'Examinare', key: 'examinare' },
            { label: 'Număr', key: 'numar' },
            { label: 'Procent', key: 'procent' },
            { label: 'Criterii', key: 'criteriu' }
        ],
        data: [
            { examinare: 'Nr. total discipline obligatorii', numar: disciplineTotal, procent: `100%`, criteriu: '' },
            { examinare: 'Nr. discipline finalizate cu Examen', numar: examinariExamenTotal, procent: `${procentExamenTotal}%`, criteriu: 'min 50%' },
            { examinare: 'Nr. discipline finalizate cu Evaluare Distribuită', numar: examinariDistribuitaTotal, procent: `${procentDistribuitaTotal}%`, criteriu: '' },
            { examinare: 'Nr. discipline finalizate cu Colocviu', numar: examinariColocviuTotal, procent: `${procentColocviuTotal}%`, criteriu: '' },
        ]
    }

    // end examinare

    // ponderi

    const oreTotal = (planSummary.find(e => e.slug === "ore-total")?.value || 0);
    const oreImpuseTotal = (planSummary.find(e => e.slug === "ore-impuse-total")?.value || 0);
    const procentOreImpuseTotal = (oreImpuseTotal / oreTotal * 100).toFixed(2);

    const oreOptionaleTotal = (planSummary.find(e => e.slug === "ore-optionale-total")?.value || 0);
    const procentOreOptionaleTotal = (oreOptionaleTotal / oreTotal * 100).toFixed(2);

    //-
    const oreDisciplineFundamentaleTotal = (planSummary.find(e => e.slug === "ore-discipline-fundamentale-total")?.value || 0);
    const procentOreDisciplineFundamentaleTotal = (oreDisciplineFundamentaleTotal / oreTotal * 100).toFixed(2);

    const oreDisciplineDomeniuTotal = (planSummary.find(e => e.slug === "ore-discipline-domeniu-total")?.value || 0);
    const procentOreDisciplineDomeniuTotal = (oreDisciplineDomeniuTotal / oreTotal * 100).toFixed(2);

    const oreDisciplineSpecialitateTotal = (planSummary.find(e => e.slug === "ore-discipline-specialitate-total")?.value || 0);
    const procentOreDisciplineSpecialitateTotal = (oreDisciplineSpecialitateTotal / oreTotal * 100).toFixed(2);

    const oreDisciplineComplementareTotal = (planSummary.find(e => e.slug === "ore-discipline-complementare-total")?.value || 0);
    const procentOreDisciplineComplementareTotal = (oreDisciplineComplementareTotal / oreTotal * 100).toFixed(2);

    const oreCursTotal = (planSummary.find(e => e.slug === "ore-curs-total")?.value || 0);
    const oreSeminarTotal = (planSummary.find(e => e.slug === "ore-seminar-total")?.value || 0);
    const oreLaboratorTotal = (planSummary.find(e => e.slug === "ore-laborator-total")?.value || 0);
    const oreProiectTotal = (planSummary.find(e => e.slug === "ore-proiect-total")?.value || 0);
    const raportOreCursAplicatii = (oreCursTotal / (oreSeminarTotal + oreLaboratorTotal + oreProiectTotal)).toFixed(2);

    const ponderiTable: GenericTableProps = {
        headers: [
            { label: 'Pondere', key: 'pondere' },
            { label: 'Număr ore', key: 'ore' },
            { label: 'Procent', key: 'procent' },
            { label: 'Criterii', key: 'criteriu' }
        ],
        data: [
            { pondere: 'Număr total de ore obligatorii (impuse+opționale)', ore: `${oreTotal}`, procent: '100%', criteriu: 'min 3152 max 3376' },
            { pondere: 'Discipline impuse', ore: `${oreImpuseTotal}`, procent: `${procentOreImpuseTotal}%`, criteriu: 'max 90%' },
            { pondere: 'Discipline opționale', ore: `${oreOptionaleTotal}`, procent: `${procentOreOptionaleTotal}%`, criteriu: 'min 10%' },
            { pondere: '-', ore: '', procent: '', criteriu: '' },
            { pondere: 'Discipline fundamentale (DF)', ore: `${oreDisciplineFundamentaleTotal}`, procent: `${procentOreDisciplineFundamentaleTotal}%`, criteriu: 'min 17%' },
            { pondere: 'Discipline în domeniu (DD)', ore: `${oreDisciplineDomeniuTotal}`, procent: `${procentOreDisciplineDomeniuTotal}%`, criteriu: 'min 38%' },
            { pondere: 'Discipline de specialitate (DS)', ore: `${oreDisciplineSpecialitateTotal}`, procent: `${procentOreDisciplineSpecialitateTotal}%`, criteriu: 'min 25%' },
            { pondere: 'Discipline complementare (DC)', ore: `${oreDisciplineComplementareTotal}`, procent: `${procentOreDisciplineComplementareTotal}%`, criteriu: 'max 8%' },
            { pondere: '-', ore: '', procent: '', criteriu: '' },
            { pondere: 'Discipline facultative', ore: ``, procent: `%`, criteriu: 'min 10%' },
            { pondere: '-', ore: '', procent: '', criteriu: '' },
            { pondere: 'Raport ore curs / ore aplicații', ore: `${raportOreCursAplicatii}`, procent: ``, criteriu: '0.8 - 1.2' },
        ]
        
    }

    // end ponderi

    // distributie ore

    const distributieOreTable: GenericTableProps = {
        headers: [
            { label: 'Anul', key: 'an' },
            {
                label: 'ore / săptămână',
                key: 'ore-saptamana',
                subHeaders: [
                    { label: 'sem. I', key: 'sem-1' },
                    { label: 'sem. 2', key: 'sem-2' }
                ]
            },
            { label: 'Criterii', key: 'criteriu' },

        ],
        data: []
    }

    props.planData.years.forEach((year, yearIdx) => {
        const oreSem1 = (year[0].summary.find(e => e.slug === "ore-saptamana")?.value || 0);
        const oreSem2 = (year[1].summary.find(e => e.slug === "ore-saptamana")?.value || 0);
        distributieOreTable.data.push({ an: `Anul ${ani[yearIdx]}`, 'sem-1': oreSem1, 'sem-2': oreSem2, criteriu: '26-28 ore/săptămână' });
    })

    // end distributie ore

    // distributie ore

    const criteriiFinalizareTable: GenericTableProps = {
        headers: [
            { label: 'Criteriu', key: 'criteriu' },
        ],
        data: [
            { criteriu: '1. Comunicarea temei proiectului de diplomă - semestrul VII' },
            { criteriu: '2. Elaborarea proiectului de diplomă - semestrul VIII' },
            { criteriu: '3. Susținerea proiectului de diplomă: sesiuni iunie, septembrie, februarie' },
        ]
    }

    // end distributie ore


    return <section className="w-full flex flex-col">
        <h1 className="text-3xl font-semibold upt-text-dark mb-5">Rezumat plan învățământ</h1>

        <div className="flex flex-col mb-5">
            <h1 className="text-2xl font-semibold upt-text-dark">I. Credite</h1>
            <GenericReadonlyTable headers={crediteTable.headers} data={crediteTable.data} />
        </div>

        <div className="flex flex-col mb-5">
            <div className="flex flex-wrap justify-around">
                <div>
                    <p>Distribuția numărului de credite pe semestre:</p>
                    <p className="text-xs mb-2">(exclusiv creditele pentru promovarea examenului de
                        diplomă)</p>
                    <GenericReadonlyTable headers={creditePeSemestru.headers} data={creditePeSemestru.data} />
                </div>
                <div>
                    <p className="mb-2">Au toate disciplinele 25 ore/credit? DEV: informatia e mereu la fel</p>
                    <GenericReadonlyTable headers={raportOreCredite.headers} data={raportOreCredite.data} />
                </div>
                <div>
                    <p className="mb-2">Numar ore/credit DEV: informatia e mereu la fel</p>
                    <GenericReadonlyTable headers={oreCreditePeSemestru.headers} data={oreCreditePeSemestru.data} />
                </div>
            </div>
        </div>

        <div className="flex flex-col my-5">
            <h1 className="text-2xl font-semibold upt-text-dark">II. Structura Anului Universitar (în nr.
                săptămâni) DEV: asta de unde se configureaza?</h1>
            <GenericReadonlyTable headers={structuraAnUniversitar.headers} data={structuraAnUniversitar.data} />
            <p className="mt-3">* Practica se elaborează pe baza unor programe elaborate în departamente și aprobate de
                Consiliul Facultății. Practica se desfășoară în cadrul facultății sau în unități
                economice de profil, pe baza unor convenții de practică. În semestrul VIII cele două săptămâni de
                practică sunt distribuite în timpul întregului semestru.</p>
        </div>

        <div className="flex flex-col my-5">
            <h1 className="text-2xl font-semibold upt-text-dark">III. Examinare</h1>
            <GenericReadonlyTable headers={examinareTable.headers} data={examinareTable.data} />
        </div>

        <div className="flex flex-col my-5">
            <h1 className="text-2xl font-semibold upt-text-dark">IV. Ponderile disciplinelor</h1>
            <GenericReadonlyTable headers={ponderiTable.headers} data={ponderiTable.data} />
        </div>

        <div className="flex flex-col my-5">
            <h1 className="text-2xl font-semibold upt-text-dark">V. Distribuția orelor la disciplinele obligatorii pe
                săptămână</h1>
            <GenericReadonlyTable headers={distributieOreTable.headers} data={distributieOreTable.data} />
            <p>Număr total ore practică:  <span>240 ore</span> <span>(min 240 ore)</span></p>
        </div>

        <div className="flex flex-col my-5">
            <h1 className="text-2xl font-semibold upt-text-dark">VI. Examenul de finalizare a studiilor</h1>
            <GenericReadonlyTable headers={criteriiFinalizareTable.headers} data={criteriiFinalizareTable.data} />
        </div>
    </section>
}
