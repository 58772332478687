import { NavLink } from "react-router-dom"

interface DrawerLinkProps {
      title: string,
      link: string,
      icon: JSX.Element
   }

export const DrawerLink = (props: DrawerLinkProps) => {
   var nonActiveLinkClasses = "flex items-center p-2 text-white rounded-lg hover:bg-indigo-600 group transition-colors duration-200 ease-in"
    return  <NavLink to={props.link} className={ ({ isActive }) => isActive ? nonActiveLinkClasses + " bg-indigo-600" : nonActiveLinkClasses}>
      <div className="flex-shrink-0 w-5 h-5 transition duration-75 font-semibold">
            {props.icon}
      </div>
    
    <span className="flex-1 ms-3 whitespace-nowrap text-sm font-medium">{props.title}</span>
 </NavLink>
}