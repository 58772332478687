import ReactDOM from 'react-dom/client';
import "./styles.scss";
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/colors.scss';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { User } from 'oidc-client-ts';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const oidcConfig: AuthProviderProps = {
  authority: "https://idp.upt.ro/application/o/gisc/",
  client_id: "EXEh66DVi912HZpaWtnUspgh65uCuPwp7FDpGBY7",
  redirect_uri: "https://dev.gisc.upt.ro//auth/callback",
  response_type: 'code',
  scope: 'openid profile email gisc.planuri.invatamant offline_access',
  disablePKCE: false,
  accessTokenExpiringNotificationTimeInSeconds: 90,
  automaticSilentRenew: true,
  response_mode: 'query',
  validateSubOnSilentRenew: true,
  refreshTokenAllowedScope: 'offline_access',
  onSigninCallback: (_user: User | void) => {
    window.history.replaceState(
      {},
      document.title,
      '/'
    );
  }
};


root.render(
  // <React.StrictMode>
  <AuthProvider {...oidcConfig}>
    <App />
  </AuthProvider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
