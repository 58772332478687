import { useState } from "react";
import { IPlanInvatamantEntryModel } from "../../models/generic-plan-invatamant-entry.model";
import { PlanEntry } from "./PlanEntry";
import { SemesterSummary } from "./SemesterSummary";
import { PlanSummaryEntryModel } from "../../models/plan-summary-entry.model";

interface YearSemesterProps {
  semesterData: { intrari: { intrare: IPlanInvatamantEntryModel, tip: string }[], summary: PlanSummaryEntryModel[] },
  index: number,
  materii,
  grupari,
  handleChange: (selected, an, semestru, idx) => void
}

export const YearSemester = (props: YearSemesterProps) => {

  const semesterData = props.semesterData;

  const an = Math.floor((props.index - 1) / 2);
  const semestru = (props.index - 1) % 2;

  return (
    <section className="w-full border-e border-gray-400">
      <div className="flex justify-center items-center p-1 bg-indigo-500">
        <p className="text-white font-semibold">Semestrul {props.index} </p>
      </div>
      <div className="w-full flex flex-col">
        {
          semesterData.intrari.map((intrare, idx) =>
            <PlanEntry
              key={idx}
              index={idx}
              entry={intrare.intrare}
              type={intrare.tip}
              comboBoxOptions={[...props.materii.filter(e => e.an === an + 1 && e.semestru === semestru + 1).map((d) => ({ label: d.nume, value: d.id })), ...props.grupari.filter(e => e.an === an + 1 && e.semestru === semestru + 1).map((d) => ({label: d.nume, value: d.id }))]}
              an={an}
              semestru={semestru}
              handleChange={props.handleChange} />)
          // materii.map((materie, index) => <PlanEntry key={index} nume={materie.nume} isGrupare={materie.isGrupare} />)
        }
        <SemesterSummary summary={semesterData.summary} />
      </div>
    </section>
  );
}