import { createContext, useEffect, useState } from "react";
import { GetPermissions } from "../api/ApiService";
import { Permission } from "../models/permission.model";

export enum PermissionType {
  UniversitateRead = "Universitate.Read",
  UniversitateWrite = "Universitate.Write",

  FacultateRead = "Facultate.Read",
  FacultateWrite = "Facultate.Write",

  LocGeograficRead = "LocGeografic.Read",
  LocGeograficWrite = "LocGeografic.Write",

  CicluStudiuRead = "CicluStudiu.Read",
  CicluStudiuWrite = "CicluStudiu.Write",

  DomeniuFundamentalRead = "DomeniuFundamental.Read",
  DomeniuFundamentalWrite = "DomeniuFundamental.Write",

  RamuraStiintaRead = "RamuraStiinta.Read",
  RamuraStiintaWrite = "RamuraStiinta.Write",

  DomeniuRead = "Domeniu.Read",
  DomeniuWrite = "Domeniu.Write",

  SpecializareRead = "Specializare.Read",
  SpecializareWrite = "Specializare.Write",

  MaterieRead = "Materie.Read",
  MaterieWrite = "Materie.Write",

  GrupareRead = "Grupare.Read",
  GrupareWrite = "Grupare.Write",

  PlanInvatamantRead = "PlanInvatamant.Read",
  PlanInvatamantWrite = "PlanInvatamant.Write",

  IndicePlanRead = "IndicePlan.Read",
  IndicePlanWrite = "IndicePlan.Write",

  MaterieValidate = "Materie.Validate",
  PlanInvatamantValidate = "PlanInvatamant.Validate",
}

interface PermissionContextType {
  hasPermission: (permissionSlug: string) => boolean
}

export const PermissionContext = createContext<PermissionContextType>({
  hasPermission: (permissionSlug: string) => false
});

export default function PermissionContextProvider({ children }) {
  const [permissions, setPermissions] = useState<Permission[]>([]);

  useEffect(() => {
    GetRequiredData().then(x => {
      setPermissions(x.permissions);
    })

  }, []);

  const GetRequiredData = async (): Promise<{ permissions: Permission[] }> => {
    let permissions = (await GetPermissions()).data;
    
    return { permissions }
  }

  const hasPermission = (permissionSlug: string): boolean => {
    return permissions.findIndex(p => p.slug === permissionSlug) != -1;
  }

  const ctxValue = {
    hasPermission: hasPermission
  }

  return <PermissionContext.Provider value={ctxValue}>
    {children}
  </PermissionContext.Provider>
}