import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IndicatorTable } from "../components/planuri-invatamant/IndicatorTable";
import { useContext, useEffect, useState } from "react";
import { Indicator } from "../models/indicator.model";
import { GetIndicatori, GetIndicatoriPlan, HandleChanges, HandleChangesIndicatoriPlan } from "../api/ApiService";
import { IndicatorPlan } from "../models/indicator-plan.model";
import { GiscModal } from "../components/Modal";
import { PermissionContext, PermissionType } from "../store/permission-context";

export default function IndicatoriPlanPage() {
  const { hasPermission } = useContext(PermissionContext);
  
  const navigate = useNavigate();
  const { id } = useParams();
  const [indicatoriPlan, setIndicatoriPlan] = useState<IndicatorPlan[]>([]);
  const [indicatori, setIndicatori] = useState<Indicator[]>([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);


  useEffect(() => {
    GetRequiredData().then(x => {
      setIndicatori(x.indicatori);
      setIndicatoriPlan(x.indicatoriPlan)
      setLoading(false);
    })
  }, []);

  const GetRequiredData = async (): Promise<{ indicatori: Indicator[], indicatoriPlan: IndicatorPlan[] }> => {
    let indicatori = (await GetIndicatori()).data;

    let indicatoriPlan = (await GetIndicatoriPlan(id)).data;

    // let validationRules = [];
    // try {
    //   validationRules = (await GetValidationRules('Grupare')).data;
    // } catch (error) {
    //   console.log('Validation rules not found');
    // }

    return { indicatori, indicatoriPlan }
  }

  const handleChange = (type: string, indicatorPlan: IndicatorPlan, value) => {

    setIndicatoriPlan(currentIndicatoriPlan => {
      var newIndicatoriPlan = [...currentIndicatoriPlan];
      var indicator = newIndicatoriPlan.find(e => e.indice.slug === indicatorPlan.indice.slug);
      if (type !== "combo") {
        switch (type) {
          case "value":
            indicatorPlan.valoare = value;
            break;
          case "minValue":
            indicatorPlan.startInterval = value;
            break;
          case "maxValue":
            indicatorPlan.finalInterval = value;
            break;
        }
        if (indicator) {
          indicator = indicatorPlan;
        }
        else {
          newIndicatoriPlan.push(indicatorPlan);
        }
      }
      else {
        var ind = indicatori.find(e => e.slug === value);
        if (ind) {
          indicatorPlan.indice = ind;
          if (indicator) {
            indicator = indicatorPlan;
          }
          else {
            newIndicatoriPlan.push(indicatorPlan);
          }
        }
        else if (indicator) {
          newIndicatoriPlan.splice(newIndicatoriPlan.indexOf(indicator), 1);
        }
      }
      
      return newIndicatoriPlan;
    });
  }

  const handleSave = () => {
    HandleChangesIndicatoriPlan(indicatoriPlan, id).then(r => {
      alert('Success!');
    })
      .catch(e => alert('o crapat :('));
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!id) {
    return <></>
  }

  return <div className="w-100 pt-20 flex flex-col min-h-screen">

    <div>
      <button onClick={() => navigate(-1)} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <svg className="w-[23px] h-[23px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12l4-4m-4 4 4 4" />
        </svg>
      </button>
    </div>
    <div className="w-100 flex justify-between align-center my-5">

      <h1 className="text-5xl font-semibold upt-text-dark">Indicatori Plan</h1>
    </div>

    <IndicatorTable indicatoriPlan={indicatoriPlan} indicatori={indicatori} handleChange={(type, indicator, value) => handleChange(type, indicator, value)} />

    {hasPermission(PermissionType.IndicePlanWrite) && <div className="flex w-1o0 justify-end py-10">
      <button className="align-center justify-center upt-blue-bg text-white px-4 py-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" onClick={() => handleSave()}>
        Salvează
      </button>
    </div>}
    <div className="flex w-1o0 justify-end py-10">
      <button className="align-center justify-center upt-blue-bg text-white px-4 py-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" onClick={() => setOpenModal(true)}>
        Deschide modal
      </button>
    </div>
    <GiscModal isOpen={openModal} title="Titlu exemplu" onClose={() => setOpenModal(false)} onConfirmClick={() => console.log("confirmed")} confirmButtonTitle="Confirma">
      <div>
        <p>Continutul modalului</p>
        <div >
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
      </div>
    </GiscModal>

  </div>;
}