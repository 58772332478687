import { IPlanInvatamantEntryModel } from "../../models/generic-plan-invatamant-entry.model";
import { YearSemester } from "./YearSemester";
import { PlanSummaryEntryModel } from "../../models/plan-summary-entry.model";

interface YearTableProps {
  yearData: { intrari: { intrare: IPlanInvatamantEntryModel, tip: string }[], summary: PlanSummaryEntryModel[] }[],
  index: number,
  materii,
  grupari,
  currentYear: number,
  handleChange: (selected, an, semestru, idx) => void
}

export const YearTable = (props: YearTableProps) => {
  const yearData = props.yearData;
  const an = props.index + 1;

  return (
    <section className="w-full" style={{ width: "1200px" }}>
      <div className="flex justify-center items-center p-2 bg-indigo-800 border-x border-gray-400">
        <p className="text-white font-semibold">ANUL {an} ({props.currentYear}-{props.currentYear + 1})</p>
      </div>
      <div className="flex w-full">
        {
          yearData.length > 0 ?
            yearData.map((semesterData, semesterIdx) => {
              return <YearSemester
                key={2 * props.index + semesterIdx + 1}
                semesterData={semesterData}
                index={2 * props.index + semesterIdx + 1}
                materii={props.materii}
                grupari={props.grupari}
                handleChange={props.handleChange}
              />;
            })
        :
        <></>
        }
      </div>

    </section>
  );
}