import { useContext, useEffect, useState } from "react";
import { Grupare } from "../models/grupare.model";
import { GetGrupari, GetMaterii, GetPlanInvatamant, GetValidatedPlanData, HandleChangesPlanInvatamant, ValidatePlanInvatamant } from "../api/ApiService";
import { Materie } from "../models/materie.model";
import { PlanInvatamant } from "../models/plan-invatamant.model";
import { IPlanInvatamantEntryModel } from "../models/generic-plan-invatamant-entry.model";
import { NavLink, useParams } from "react-router-dom";
import { DropdownOption } from "../components/renderers/DropdownRenderer";

import { Plan } from "../components/planuri-invatamant/PlanInvatamant";
import { PlanInvatamantTableData } from "../models/plan-invatamant-table-data.model";
import { PlanSummaryEntryModel } from "../models/plan-summary-entry.model";
import { PlanSummary } from "../components/planuri-invatamant/PlanSummary";
import { PermissionContext, PermissionType } from "../store/permission-context";

const DEFAULT_MAX_YEARS = 4;

let defaultSemesterSummaryObject: PlanSummaryEntryModel[] = [
  { slug: "credite-semestru", value: 0, error: "" },
  { slug: "discipline-semestru", value: 0, error: "" },
  { slug: "ore-semestru", value: 0, error: "" },
  { slug: "ore-saptamana", value: 0, error: "" },
  { slug: "ore-curs-total", value: 0, error: "" },
  { slug: "ore-curs-saptamana", value: 0, error: "" },
  { slug: "ore-seminar-total", value: 0, error: "" },
  { slug: "ore-seminar-saptamana", value: 0, error: "" },
  { slug: "ore-laborator-total", value: 0, error: "" },
  { slug: "ore-laborator-saptamana", value: 0, error: "" },
  { slug: "ore-proiect-total", value: 0, error: "" },
  { slug: "ore-proiect-saptamana", value: 0, error: "" },
  { slug: "ore-individuale-total", value: 0, error: "" },
  { slug: "ore-practica", value: 0, error: "" },
  { slug: "ore-discipline-fundamentale", value: 0, error: "" },
  { slug: "ore-discipline-domeniu", value: 0, error: "" },
  { slug: "ore-discipline-specialitate", value: 0, error: "" },
  { slug: "ore-discipline-complementare", value: 0, error: "" },
  { slug: "evaluari-examen", value: 0, error: "" },
  { slug: "evaluari-distribuita", value: 0, error: "" },
  { slug: "evaluari-colocviu", value: 0, error: "" },
];

let defaultPlanSummaryObject: PlanSummaryEntryModel[] = [
  { slug: "credite-total", value: 0, error: "" },
  { slug: "credite-optionale-total", value: 0, error: "" },
  { slug: "discipline-total", value: 0, error: "" },
  { slug: "ore-total", value: 0, error: "" },
  { slug: "ore-impuse-total", value: 0, error: "" },
  { slug: "ore-optionale-total", value: 0, error: "" },
  { slug: "ore-curs-total", value: 0, error: "" },
  { slug: "ore-seminar-total", value: 0, error: "" },
  { slug: "ore-laborator-total", value: 0, error: "" },
  { slug: "ore-proiect-total", value: 0, error: "" },
  // { slug: "ore-practica-total", value: 0, error: "" },
  { slug: "ore-discipline-fundamentale-total", value: 0, error: "" },
  { slug: "ore-discipline-domeniu-total", value: 0, error: "" },
  { slug: "ore-discipline-specialitate-total", value: 0, error: "" },
  { slug: "ore-discipline-complementare-total", value: 0, error: "" },
  { slug: "evaluari-examen-total", value: 0, error: "" },
  { slug: "evaluari-distribuita-total", value: 0, error: "" },
  { slug: "evaluari-colocviu-total", value: 0, error: "" },
  { slug: "raport-ore-curs-activitate", value: 0, error: "" }
];

export const PlanInvatamantPage = () => {
  const { hasPermission } = useContext(PermissionContext);

  // const [sheetData, setSheetData] = useState<{ rows: IModel[], errors: ValidatedCellError[], modifiedEntriesIds: string[], deletedEntriesIds: string[] }>({ rows: [], errors: [], modifiedEntriesIds: [], deletedEntriesIds: [], });
  const [planInvatamant, setPlanInvatamant] = useState<PlanInvatamant>();
  const [tableData, setTableData] = useState<PlanInvatamantTableData>({ years: [], summary: structuredClone(defaultPlanSummaryObject) });
  const [grupari, setGrupari] = useState<Grupare[]>([]);
  const [grupariSelectate, setGrupariSelectate] = useState<Grupare[]>([]);
  const [materii, setMaterii] = useState<Materie[]>([]);
  const [validateButtonEnabled, setValidateButtonEnabled] = useState(true);
  const [loading, setLoading] = useState(true);

  const planInvatamantId = useParams();

  useEffect(() => {
    GetRequiredData(planInvatamantId.id || "").then(async x => {
      setPlanInvatamant(x.planInvatamant)
      var tempTableDate = await prepareTableData(x.planInvatamant)
      setTableData(tempTableDate);
      setGrupari(x.grupari);
      setMaterii(x.materii);
    })
  }, []);

  useEffect(() => {
    if (tableData.years && tableData.years.length > 0) {
      setLoading(false);
    }
  }, [tableData])

  const GetRequiredData = async (id: string): Promise<{ planInvatamant: PlanInvatamant, grupari: Grupare[], materii: Materie[] }> => {
    let planInvatamant = (await GetPlanInvatamant(id)).data;

    let grupari = (await GetGrupari()).data;

    let materii = (await GetMaterii()).data;

    return { planInvatamant, grupari, materii }
  }

  const calculateSummaryData = (data: PlanInvatamantTableData) => {
    const orePairs = {
      "ore-saptamana": "ore-semestru",
      "ore-curs-saptamana": "ore-curs-total",
      "ore-seminar-saptamana": "ore-seminar-total",
      "ore-laborator-saptamana": "ore-laborator-total",
      "ore-proiect-saptamana": "ore-proiect-total"
    }

    const oreTotalStrings = [
      "ore-curs-total",
      "ore-seminar-total",
      "ore-laborator-total",
      "ore-proiect-total"
    ]

    let summary: PlanSummaryEntryModel | undefined;
    data.summary = structuredClone(defaultPlanSummaryObject);
    data.years.forEach(year => {
      year.forEach(semester => {
        semester.summary = structuredClone(defaultSemesterSummaryObject);

        semester.intrari.forEach(intrare => {
          if (intrare.tip !== "Empty") {
            let entry = intrare.intrare;
            if (intrare.tip === "GrupareViewModel") {
              let grupareEntry = intrare.intrare as Grupare;
              if (grupareEntry && grupareEntry.materie) {
                entry = grupareEntry.materie[0];
                summary = data.summary.find(e => e.slug === "credite-optionale-total");
                if (summary) { summary.value += entry.nrCredite; }
                summary = data.summary.find(e => e.slug === "ore-optionale-total");
                if (summary) { summary.value += entry.oreCurs + entry.oreSeminar + entry.oreLaborator + entry.oreProiect; }
              }
            }
            summary = semester.summary.find(e => e.slug === "credite-semestru");
            if (summary) { summary.value += entry.nrCredite; }

            //ore
            summary = semester.summary.find(e => e.slug === "ore-curs-total");
            if (summary) { summary.value += entry.oreCurs; }
            summary = semester.summary.find(e => e.slug === "ore-seminar-total");
            if (summary) { summary.value += entry.oreSeminar; }
            summary = semester.summary.find(e => e.slug === "ore-laborator-total");
            if (summary) { summary.value += entry.oreLaborator; }
            summary = semester.summary.find(e => e.slug === "ore-proiect-total");
            if (summary) { summary.value += entry.oreProiect; }
            summary = semester.summary.find(e => e.slug === "ore-individuale-total");
            if (summary) { summary.value += entry.oreIndividuale; }
            summary = semester.summary.find(e => e.slug === "ore-practica");
            if (summary) { summary.value += entry.orePractica; }

            switch (entry.categorieFormativa) {
              case 'DF':
                summary = semester.summary.find(e => e.slug === "ore-discipline-fundamentale");
                if (summary) { summary.value += entry.oreCurs + entry.oreSeminar + entry.oreLaborator + entry.oreProiect; }
                break;
              case 'DD':
                summary = semester.summary.find(e => e.slug === "ore-discipline-domeniu");
                if (summary) { summary.value += entry.oreCurs + entry.oreSeminar + entry.oreLaborator + entry.oreProiect; }
                break;
              case 'DS':
                summary = semester.summary.find(e => e.slug === "ore-discipline-specialitate");
                if (summary) { summary.value += entry.oreCurs + entry.oreSeminar + entry.oreLaborator + entry.oreProiect; }
                break;
              case 'DC':
                summary = semester.summary.find(e => e.slug === "ore-discipline-complementare");
                if (summary) { summary.value += entry.oreCurs + entry.oreSeminar + entry.oreLaborator + entry.oreProiect; }
                break;
              default:
                break;
            }

            summary = semester.summary.find(e => e.slug === "evaluari-examen");
            if (summary) { summary.value += entry.formaEvaluare === "E" ? 1 : 0; }
            summary = semester.summary.find(e => e.slug === "evaluari-distribuita");
            if (summary) { summary.value += entry.formaEvaluare === "D" ? 1 : 0; }
            summary = semester.summary.find(e => e.slug === "evaluari-colocviu");
            if (summary) { summary.value += entry.formaEvaluare === "C" ? 1 : 0; }

            //TODO aici trebuie contorizate doar disciplinele obligatorii
            summary = semester.summary.find(e => e.slug === "discipline-semestru");
            if (summary) { summary.value += 1; }
          }
        })

        summary = data.summary.find(e => e.slug === "discipline-total");
        if (summary) { summary.value += (semester.summary.find(e => e.slug === "discipline-semestru")?.value || 0); }

        summary = data.summary.find(e => e.slug === "evaluari-examen-total");
        if (summary) { summary.value += (semester.summary.find(e => e.slug === "evaluari-examen")?.value || 0); }
        summary = data.summary.find(e => e.slug === "evaluari-distribuita-total");
        if (summary) { summary.value += (semester.summary.find(e => e.slug === "evaluari-distribuita")?.value || 0); }
        summary = data.summary.find(e => e.slug === "evaluari-colocviu-total");
        if (summary) { summary.value += (semester.summary.find(e => e.slug === "evaluari-colocviu")?.value || 0); }

        let ore: PlanSummaryEntryModel | undefined;
        summary = semester.summary.find(e => e.slug === "ore-semestru");
        if (summary) {
          summary.value = (semester.summary.find(e => e.slug === "ore-curs-total")?.value || 0) +
            (semester.summary.find(e => e.slug === "ore-seminar-total")?.value || 0) +
            (semester.summary.find(e => e.slug === "ore-laborator-total")?.value || 0) +
            (semester.summary.find(e => e.slug === "ore-proiect-total")?.value || 0);
        }

        summary = semester.summary.find(e => e.slug === "ore-semestru");
        if (summary) {
          summary.value = (semester.summary.find(e => e.slug === "ore-curs-total")?.value || 0) +
            (semester.summary.find(e => e.slug === "ore-seminar-total")?.value || 0) +
            (semester.summary.find(e => e.slug === "ore-laborator-total")?.value || 0) +
            (semester.summary.find(e => e.slug === "ore-proiect-total")?.value || 0);
        }

        summary = data.summary.find(e => e.slug === "ore-discipline-fundamentale-total");
        if (summary) { summary.value += (semester.summary.find(e => e.slug === "ore-discipline-fundamentale")?.value || 0); }
        summary = data.summary.find(e => e.slug === "ore-discipline-domeniu-total");
        if (summary) { summary.value += (semester.summary.find(e => e.slug === "ore-discipline-domeniu")?.value || 0); }
        summary = data.summary.find(e => e.slug === "ore-discipline-specialitate-total");
        if (summary) { summary.value += (semester.summary.find(e => e.slug === "ore-discipline-specialitate")?.value || 0); }
        summary = data.summary.find(e => e.slug === "ore-discipline-complementare-total");
        if (summary) { summary.value += (semester.summary.find(e => e.slug === "ore-discipline-complementare")?.value || 0); }

        Object.keys(orePairs).forEach(key => {
          summary = semester.summary.find(e => e.slug === key);
          ore = semester.summary.find(e => e.slug === orePairs[key]);
          if (summary && ore) { summary.value = ore.value / 14; }
        })

        oreTotalStrings.forEach(oreString => {
          summary = data.summary.find(e => e.slug === oreString);
          if (summary) { summary.value += (semester.summary.find(e => e.slug === oreString)?.value || 0); }
        })

        summary = data.summary.find(e => e.slug === "credite-total");
        if (summary) { summary.value += (semester.summary.find(e => e.slug === "credite-semestru")?.value || 0); }
        summary = data.summary.find(e => e.slug === "ore-total");
        if (summary) { summary.value += (semester.summary.find(e => e.slug === "ore-semestru")?.value || 0); }

      })
    })

    summary = data.summary.find(e => e.slug === "ore-impuse-total");
    if (summary) { summary.value = (data.summary.find(e => e.slug === "ore-total")?.value || 0) - (data.summary.find(e => e.slug === "ore-optionale-total")?.value || 0); }
  }

  const prepareTableData = async (planInvatamant: PlanInvatamant) => {
    var maxYear = (planInvatamant.indiciPlan.find(e => e.indice.slug === "durata-studii")?.valoare || DEFAULT_MAX_YEARS);
    var data: PlanInvatamantTableData = { years: [], summary: structuredClone(defaultPlanSummaryObject) };
    var prevMaxOrder = 0;

    for (let yearIdx = 0; yearIdx < maxYear; yearIdx++) {
      var tempIntrariAn = planInvatamant.intrari.filter(e => e.intrare.an === yearIdx + 1);
      data.years[yearIdx] = [{ intrari: [], summary: structuredClone(defaultSemesterSummaryObject) }, { intrari: [], summary: structuredClone(defaultSemesterSummaryObject) }];

      for (let semesterIdx = 0; semesterIdx < 2; semesterIdx++) {
        var tempIntrariSemestru = tempIntrariAn.filter(e => e.intrare.semestru === semesterIdx + 1);

        var maxOrder = tempIntrariSemestru.length > 0 ? Math.max(...tempIntrariSemestru.map(e => e.ordine)) : 0;

        var intrariIdx = 0;
        for (let orderIdx = 0; tempIntrariSemestru.length > 0 && intrariIdx < tempIntrariSemestru.length; orderIdx++) {
          if (tempIntrariSemestru[intrariIdx].ordine === orderIdx + prevMaxOrder + (prevMaxOrder != 0 ? 1 : 0)) {
            data.years[yearIdx][semesterIdx].intrari.push(tempIntrariSemestru[intrariIdx++]);
          }
          else {
            data.years[yearIdx][semesterIdx].intrari.push({ intrare: {} as IPlanInvatamantEntryModel, tip: "Empty" });
          }
        }
        prevMaxOrder = maxOrder > prevMaxOrder ? maxOrder : prevMaxOrder;
      }
    }
    calculateSummaryData(data);
    let validatedPlanData = (await GetValidatedPlanData(planInvatamant, data)).data;

    if (validatedPlanData) {
      var summary = validatedPlanData.item as PlanInvatamantTableData;
      data.years.forEach((year, yearIdx) => {
        year.forEach((semester, semesterIdx) => {
          semester.summary = summary.years[yearIdx][semesterIdx].summary;
        })
      })
      data.summary = summary.summary;
    }

    return data;
  }

  const getCombinedTableData = () => {
    var intrari: { intrare: IPlanInvatamantEntryModel, tip: string, ordine: number }[] = [];
    var ordine = 0;
    tableData.years.forEach(year => {
      year.forEach(semester => {
        semester.intrari.forEach(intrare => {
          if (intrare.tip !== "Empty") {
            intrari.push({ intrare: intrare.intrare, tip: intrare.tip, ordine: ordine });
          }
          ordine++;
        })

        ordine = intrari.length > 0 ? intrari[intrari.length - 1].ordine + 1 : ordine; //Reseteaza ordinea la maximul de pana acum, in cazul in care au fost celule goale dupa ultima intrare dintr-un an
      })
    });

    return intrari;
  }

  const handleValidate = () => {
    ValidatePlanInvatamant(planInvatamant?.id || "", tableData).then(r => {
      alert('Success!');
    })
      .catch(e => alert('o crapat :('));
  }

  const handleSave = () => {
    var newPlanInvatamant = { ...planInvatamant };

    newPlanInvatamant.intrari = getCombinedTableData();

    // console.log(newPlanInvatamant);

    HandleChangesPlanInvatamant([newPlanInvatamant as PlanInvatamant], [], 'plan-invatamant')
      .then(r => {
        alert('Success!');
        setValidateButtonEnabled(true);
      })
      .catch(e => {
        alert('o crapat :(')
        setValidateButtonEnabled(false);
      });
  }

  const handleChange = async (selected: DropdownOption | null, yearIdx: number, semesterIdx: number, idx: number) => {
    var newTableData = { ...tableData };
    const newGrupariSelectate = [...grupariSelectate];
    if (selected) {
      //Aici trebuie facuta diferentierea in functie de array-ul din care face parte selectedEntity
      if (newTableData.years[yearIdx][semesterIdx]?.intrari) {
        var selectedEntity: Materie | Grupare | undefined = materii.find(i => i.id === selected.value);
        if (selectedEntity) {
          newTableData.years[yearIdx][semesterIdx].intrari[idx] = {
            ...newTableData.years[yearIdx][semesterIdx].intrari[idx],
            tip: "MaterieViewModel",
            intrare: selectedEntity
          };
        }
        else {
          selectedEntity = grupari.find(i => i.id === selected.value);
          if (selectedEntity) {
            newTableData.years[yearIdx][semesterIdx].intrari[idx] = {
              ...newTableData.years[yearIdx][semesterIdx].intrari[idx],
              tip: "GrupareViewModel",
              intrare: selectedEntity
            };
            if (!newGrupariSelectate.includes(selectedEntity)) {
              newGrupariSelectate.push(selectedEntity);
              setGrupariSelectate(newGrupariSelectate);
            }
          }
        }
      }
    }
    else {
      if (newTableData.years[yearIdx][semesterIdx]?.intrari) {
        newTableData.years[yearIdx][semesterIdx].intrari[idx] = {
          ...newTableData.years[yearIdx][semesterIdx].intrari[idx],
          tip: "Empty",
          intrare: {} as IPlanInvatamantEntryModel
        };
      }
    }
    calculateSummaryData(newTableData);
    let validatedPlanData = (await GetValidatedPlanData(planInvatamant as PlanInvatamant, newTableData)).data;
    if (validatedPlanData) {
      var summary = validatedPlanData.item as PlanInvatamantTableData;
      newTableData.years.forEach((year, yearIdx) => {
        year.forEach((semester, semesterIdx) => {
          semester.summary = summary.years[yearIdx][semesterIdx].summary;
        })
      })
      newTableData.summary = summary.summary;
    }
    setTableData(newTableData);
    setValidateButtonEnabled(false);
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return <div className="w-100 pt-20 flex flex-col min-h-screen">
    <div className="w-100 flex justify-between align-center my-5">
      <h1 className="text-5xl font-semibold upt-text-dark">Plan învățământ</h1>
    </div>
    {hasPermission(PermissionType.IndicePlanRead) && <div className="flex w-1o0 justify-end py-10">
      <NavLink to={'indicatori'}>
        <button disabled={planInvatamant ? false : true} className="align-center justify-center upt-blue-bg text-white px-4 py-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
          Indicatori
        </button>
      </NavLink>
    </div>}
    <div className="w-100 flex justify-between align-center my-5">
      <h3 className="text-3xl font-semibold upt-text-dark">Ani studiu</h3>
    </div>
    <div className="w-full flex flex-col">
      <Plan
        planData={tableData}
        materii={materii}
        grupari={grupari}
        handleChange={(selected, an, semesterIdx, idx) => handleChange(selected, an, semesterIdx, idx)}
      />



      {planInvatamant ? <PlanSummary planData={tableData} /> : <></>}

      <div className="flex w-1o0 justify-end py-10">
        {hasPermission(PermissionType.PlanInvatamantValidate) && <button className="align-center justify-center upt-blue-bg text-white px-4 py-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" disabled={!validateButtonEnabled} onClick={() => handleValidate()}>
          Validează
        </button>}
        {hasPermission(PermissionType.PlanInvatamantWrite) && <button className="align-center justify-center upt-blue-bg text-white px-4 py-2 ml-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" onClick={() => handleSave()}>
          Salvează
        </button>}
      </div>
    </div>

    {/* {
      sheetData.errors.length > 0 ? <div className="bg-rose-100 border-t-4 rounded-b text-rose-900 px-4 py-3 shadow-md mb-4" role="alert">
        <div className="flex">
          <div className="py-1"><svg className="fill-current h-6 w-6 text-rose-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
          <div>
            <p className="font-bold">Au fost găsite erori în următoarele schimbări</p>
            {sheetData.errors.map(e => <p className="text-sm">Coloana {camelToFlat(e.rule.fieldName)}, rândul {sheetData.rows.findIndex(r => r.id === e.rowId) + 1}, cu valoarea "{e.value}" are eroarea: {e.rule.errorMessage}.</p>)}
          </div>
        </div>
      </div> : <></>
    } */}


  </div>;
}
