import { useContext, useEffect, useState } from "react";
import { GetPlanuriInvatamant } from "../api/ApiService";
import { Materie } from "../models/materie.model";
import { PlanInvatamant } from "../models/plan-invatamant.model";
import { NavLink, useParams } from "react-router-dom";
import { Column, SelectColumn, textEditor } from "react-data-grid";
import { RenderDropdown } from "../components/renderers/DropdownRenderer";
import { HeaderCellRenderer } from "../components/renderers/HeaderCellRenderer";
import { SheetPage } from "./SheetPage";
import { AddPlanModal } from "../components/planuri-invatamant/AddPlanModal";
import { GenericReadonlyTable, GenericTableProps } from "../components/GenericReadonlyTable";
import PlanContextProvider from "../store/plan-context";
import { PermissionContext, PermissionType } from "../store/permission-context";


export const PlanuriInvatamantPage = () => {
  const { hasPermission } = useContext(PermissionContext);

  // const [sheetData, setSheetData] = useState<{ rows: IModel[], errors: ValidatedCellError[], modifiedEntriesIds: string[], deletedEntriesIds: string[] }>({ rows: [], errors: [], modifiedEntriesIds: [], deletedEntriesIds: [], });
  const [planuriInvatamant, setPlanuriInvatamant] = useState<PlanInvatamant[]>([]);
  const [filters, setFilters] = useState<{ filterName: string, filterValue: string }[]>([]);
  const [loading, setLoading] = useState(true);
  const [addPlanModalOpen, setAddPlanModalOpen] = useState(false);



  useEffect(() => {
    GetRequiredData().then(x => {
      setPlanuriInvatamant(x.planuriInvatamant);
      setLoading(false);
    })

  }, []);

  const GetRequiredData = async (): Promise<{ planuriInvatamant: PlanInvatamant[] }> => {
    let planuriInvatamant = (await GetPlanuriInvatamant()).data;

    // let validationRules = [];
    // try {
    //   validationRules = (await GetValidationRules('Grupare')).data;
    // } catch (error) {
    //   console.log('Validation rules not found');
    // }

    return { planuriInvatamant }
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  // return <div className="w-100 pt-20 flex flex-col min-h-screen">
  //   <div className="w-100 flex justify-between align-center my-5">
  //     <h1 className="text-5xl font-semibold upt-text-dark">Planuri învățământ</h1>
  //   </div>

  //   {
  //     planuriInvatamant?.map(planInvatamant =>
  //       <NavLink key={planInvatamant.id} to={planInvatamant.id} className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">

  //         <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{planInvatamant.nume}</h5>
  //         <p className="font-normal text-gray-700 dark:text-gray-400">{planInvatamant.domeniu?.numeDl}</p>
  //         <p className="font-normal text-gray-700 dark:text-gray-400">{planInvatamant.specializare?.nume}</p>
  //         <p className="font-normal text-gray-700 dark:text-gray-400">{planInvatamant.anUniversitar}</p>
  //       </NavLink>
  //     )
  //   }


  //   <div className="flex w-1o0 justify-end py-10">
  //     <button className="align-center justify-center upt-blue-bg text-white px-4 py-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" onClick={() => handleSave()}>
  //       Adaugă
  //     </button>
  //   </div>

  // </div>;

  const planuriTable: GenericTableProps = {
    headers: [
      { label: 'Nume', key: 'nume' },
      { label: 'Domeniu', key: 'numeDomeniu' },
      { label: 'Specializare', key: 'numeSpecializare' },
      { label: 'An universitar', key: 'anUniversitar' },
      { label: 'An studiu', key: 'anStudiu' },
      { label: 'Validat', key: 'validated' },

    ],
    data: planuriInvatamant.map(p => { return { ...p, numeDomeniu: p.domeniu?.numeDl, numeSpecializare: p.specializare?.nume } })
  }

  return <PlanContextProvider>

    <div className="flex justify-between py-10" style={{ marginTop: "60px" }}>
      <h1 className="text-5xl font-semibold upt-text-dark">Planuri de învațământ</h1>
      {hasPermission(PermissionType.PlanInvatamantWrite) && <button className="align-center justify-center upt-blue-bg text-white px-4 py-2 text-sm font-semibold rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" onClick={() => setAddPlanModalOpen(true)}>
        Adaugă plan de învățământ
      </button>}
    </div>
    {hasPermission(PermissionType.PlanInvatamantWrite) && <AddPlanModal addPlanModalOpen={addPlanModalOpen} onClose={() => setAddPlanModalOpen(false)} />}

    <div className="flex align-center justify-center w-full">
      <GenericReadonlyTable headers={planuriTable.headers} data={planuriTable.data} showViewButton={true} viewButtonLabel="Vizualizează planul" />
    </div>
  </PlanContextProvider >
}
