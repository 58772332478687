export interface DetailBoxProps {
    value: string | number;
    tooltip?: string;
    isError?: boolean;
}

export const DetailBox = (props: DetailBoxProps) => {
    const errorClasses  = props.isError ? "bg-red-300" : "bg-orange-50";
    return (
     <section className="w-full group relative">
          <div className={"flex justify-center items-center border-gray-400 border-e border-y min-h-7 px-2 " + errorClasses}>
            {
                props.isError ?  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 mr-1 text-white">
                <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8-5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 10 5Zm0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" clipRule="evenodd" />
              </svg>
               : <></>
            }
           
            <p>
                {props.value} 
            </p>
          </div>
          {props.tooltip && (<span className="absolute -top-10 scale-0 transition-all rounded bg-gray-400 p-2 text-xs text-white group-hover:scale-100 z-10 text-center" style={{width: "150px"}}>{props.tooltip}</span>)}
          
     </section>
    );
  }