import { SheetPage } from "./SheetPage";
import { ValidationRule } from "../models/validation-rule.model";
import { GetDomeniiWithFilters, GetRamuriStiinta, GetValidationRules, HandleChanges } from "../api/ApiService";
import { useEffect, useState } from "react";
import { Column, SelectColumn, textEditor } from "react-data-grid";
import { DropdownOption, RenderDropdown } from "../components/renderers/DropdownRenderer";
import { HeaderCellRenderer } from "../components/renderers/HeaderCellRenderer";
import { Domeniu } from "../models/domeniu.model";
import { RamuraStiinta } from "../models/ramura-stiinta.model";
import { PermissionType } from "../store/permission-context";

export const DomeniiPage = () => {
  const [domenii, setDomenii] = useState<Domeniu[]>([]);
  const [ramuriStiinta, setRamuriStiinta] = useState<RamuraStiinta[]>([]);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [filters, setFilters] = useState<{ filterName: string, filterValue: string }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetRequiredData().then(x => {
      setDomenii(x.domenii);
      setRamuriStiinta(x.ramuriStiinta);
      setValidationRules(x.validationRules);
      setLoading(false);
    })
  }, [filters]);

  const GetRequiredData = async (): Promise<{ ramuriStiinta: RamuraStiinta[], domenii: Domeniu[], validationRules: ValidationRule[] }> => {
    let domenii = (await GetDomeniiWithFilters(filters)).data;

    let ramuriStiinta = (await GetRamuriStiinta()).data;

    let validationRules = [];
    try {
      validationRules = (await GetValidationRules('Domeniu')).data;
    } catch (error) {
      console.log('Validation rules not found');
    }

    return { ramuriStiinta: ramuriStiinta, domenii, validationRules }
  }

  const applyFilter = (filterName: string, filterValue: string) => {
    setFilters(oldFilters => [...oldFilters.filter(f => f.filterName !== filterName && f.filterValue !== ''), { filterName, filterValue }]);
  }

  const getColumns = (): Column<Domeniu>[] => {
    return [
      SelectColumn,
      {
        key: 'codDsu',
        name: 'Cod DSU',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ' '}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'numeDsu',
        name: 'Nume Domeniu Studii Universitare',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ' '}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'codDl',
        name: 'Cod DL',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ' '}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'numeDl',
        name: 'Nume Domeniu Licenta',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ' '}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'ramuraStiinta',
        name: 'Ramura stiinta',
        renderEditCell: (p) => {
          return <RenderDropdown {...p} options={ramuriStiinta.map((d) => ({ label: d.nume, value: d.id }))} items={ramuriStiinta} handleChange={handleRamuraStiintaDropdownChange} foreignKeyItemKey='ramStiintaId' />
        },
        renderCell: (p) => (<>{p.row.ramuraStiinta?.nume}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === 'ramuraStiinta')?.filterValue || ' '}
            applyFilter={val => applyFilter('ramuraStiinta', val)}
          />)
      }
    ]
  };

  const handleRamuraStiintaDropdownChange = (row: Domeniu, columnKey: string, selected: DropdownOption | null, items: RamuraStiinta[]) => {
    if (selected) {
      const found = items.find(i => i.id === selected.value);
      if (!found) {
        row['ramStiintaId'] = '';
        delete row['ramuraStiinta'];
      }
      else {
        row['ramStiintaId'] = found.id;
        row['ramuraStiinta'] = found;
      }
    }
    else {
      row['ramStiintaId'] = '';
      delete row['ramuraStiinta'];
    }

    return row;
  }

  const rowsToString = (rows: Domeniu[], columnsOrder: readonly number[]): string => {
    var copiedString = '';
    const columnsKeys = getColumns().map(c => c.key).slice(1);

    rows.forEach(row => {
      for (let idx = 0; idx < columnsKeys.length; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        var separator = idx == columnsKeys.length - 1 ? '\r\n' : '\t';

        switch (columnKey) {
          case 'ramuraStiinta':
            var ramuraStiinta = ramuriStiinta.find(e => e.id == row.ramuraStiinta?.id);
            copiedString += ramuraStiinta?.nume;
            break;

          default:
            copiedString += row[columnKey as keyof Domeniu];
            break;
        }
        copiedString += separator
      }
    })

    return copiedString;
  }

  const stringToRows = (splitText: string[][], columnsOrder: readonly number[]): Domeniu[] => {
    var rows: Domeniu[] = [];
    const columnsKeys = getColumns().map(c => c.key).slice(1);

    splitText.forEach(textRow => {
      var row: Domeniu = structuredClone(defaultObject) as Domeniu;
      row.id = crypto.randomUUID();
      for (let idx = 0; idx < columnsKeys.length; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'ramuraStiinta':
            var ramuraStiinta = ramuriStiinta.find(e => e.nume == textRow[idx]);
            row['ramStiintaId'] = ramuraStiinta?.id ?? "";
            row['ramuraStiinta'] = ramuraStiinta;
            break;
          default:
            row[columnKey] = textRow[idx];
            break;
        }
      }
      rows.push(row);
    })

    return rows;
  }

  const addPastedRows = (currentRows: Domeniu[], rowIdx: number, columnIdx: number, columnsOrder: readonly number[], splitText: string[][]): { rows: Domeniu[], indexes: number[] } => {
    var initialRowCount = currentRows.length;
    var currentSheetRowIdx = rowIdx;
    const columnsKeys = columns.map(c => c.key).slice(1);

    const transformedClipboardRows = stringToRows(splitText, columnsOrder);

    var newSheetRows = [...currentRows];
    splitText.forEach((clipboardRow, clipboardRowIdx) => {
      var clipboardColumnIdx = 0;

      var row = structuredClone(defaultObject) as Domeniu;
      row.id = crypto.randomUUID();
      if (currentSheetRowIdx < initialRowCount - 1) {
        row = currentRows[currentSheetRowIdx] as Domeniu;
      }

      for (let idx = columnIdx - 1; idx < columnsOrder.length; idx++) {
        if (clipboardColumnIdx == clipboardRow.length) { //if clipboard row has reached the last column
          break;
        }

        var columnKey: string = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'ramuraStiinta':
            row['ramStiintaId'] = transformedClipboardRows[clipboardRowIdx]['ramuraStiinta']?.id || '';
            break;
          default:
            break;
        }
        row[columnKey] = transformedClipboardRows[clipboardRowIdx][columnKey];
      }

      if (currentSheetRowIdx <= initialRowCount) { //if we have not reached the end of the sheet, we edit the current row, else add a new one
        newSheetRows[currentSheetRowIdx++] = row;
      }
      else {
        newSheetRows.push(row);
      }
    })

    const indexes = [...Array(transformedClipboardRows.length).keys()].map(i => i + rowIdx);

    return { rows: newSheetRows, indexes: indexes };
  }

  // to detect empty objects or deleted objects
  const hasEmptyProperties = (row: Domeniu): boolean => {
    if (row.ramStiintaId !== '' || !!row.ramStiintaId) return false;
    if (row.numeDl !== '' || !!row.numeDl) return false;
    if (row.numeDsu !== '' || !!row.numeDsu) return false;
    if (row.codDl !== '' || !!row.codDl) return false;
    if (row.codDsu !== '' || !!row.codDsu) return false;
    return true;
  }

  const refreshTable = (): void => {
    setFilters([...filters]);
  }

  const columns = getColumns();
  let defaultObject: Domeniu = { id: '', numeDl: '', numeDsu: '', ramStiintaId: '', codDl: '', codDsu: '' };

  if (loading) {
    return <div>Loading...</div>;
  }

  return <SheetPage
    requiredEditPermission={PermissionType.DomeniuWrite}
    sheetColumns={columns}
    title={'Domenii'}
    hasEmptyProperties={e => hasEmptyProperties(e as Domeniu)}
    initialEntries={domenii}
    validationRules={validationRules}
    onSave={(modifiedEntries, deletedEntries) => HandleChanges(modifiedEntries, deletedEntries, 'domeniu')}
    rowsToString={(rows, columnsOrder) => rowsToString(rows as Domeniu[], columnsOrder)}
    stringToRows={(splitText, columnsOrder) => stringToRows(splitText, columnsOrder)}
    addPastedRows={(currentRows, rowIdx, columnIdx, columnsOrder, splitText) => addPastedRows(currentRows, rowIdx, columnIdx, columnsOrder, splitText)}
    deleteCell={(currentRows, rowIdx, columnKey) => deleteCell(currentRows, rowIdx, columnKey)}
    defaultObject={defaultObject}
    refreshTable={refreshTable} />
}