import { useState } from "react";
import { IPlanInvatamantEntryModel } from "../../models/generic-plan-invatamant-entry.model";
import { IndicatorPlan } from "../../models/indicator-plan.model";
import ComboBox from "../Combobox";
import { DetailBox } from "./DetailBox";

interface IndicatorPlanEntryProps {
    entry: IndicatorPlan,
    comboBoxOptions: { label: string, value: string }[],
    handleChange: (type: string, indicator: IndicatorPlan, value) => void
}





export const IndicatorPlanEntry = (props: IndicatorPlanEntryProps) => {

    const handleChange = (type: string, value) => {
        props.handleChange(type, props.entry, value);
        
    }

    const GetInputForIndice = (entry: IndicatorPlan) => {
        switch (entry.indice.tip) {
            case "ValoareExacta":
                return <input type="number" placeholder="Valoare indice" value={entry.valoare} onChange={v => handleChange('value', v.target.value)} step="0.1" className="bg-orange-50 p-2 w-full flex border-gray-400 border-e border-y"/>
    
            case "Procent":
                return <input type="number" placeholder="Valoare procent" value={entry.valoare} onChange={v => handleChange('value', v.target.value)} className="bg-orange-50 p-2 w-full flex border-gray-400 border-e border-y"/>
    
            case "Interval":
                return <>
                    <input type="number" placeholder="Valoare minima" value={entry.startInterval} onChange={v => handleChange('minValue', v.target.value)} className="bg-orange-50 p-2 w-full flex border-gray-400 border-e border-y"/>
                    <input type="number" placeholder="Valoare maxima" value={entry.finalInterval} onChange={v => handleChange('maxValue', v.target.value)} className="bg-purple-50 p-2 w-full flex border-gray-400 border-e border-y"/>
                </>
        }
    }

    return (
        <section className="w-full">
            <div className="flex">
                <div className="w-full flex">
                        <ComboBox options={props.comboBoxOptions} value={{ label: (props.entry.indice.nume || ""), value: (props.entry.indice.slug || "") }} onChange={v => handleChange('combo', v?.value)} />
                        <div className="flex w-4/12"> 
                        {
                            GetInputForIndice(props.entry)
                        }
                        </div>
                </div> 
            </div>
        </section>
    );
}