import { IModel } from "../models/generic.model";
import { IndicatorPlan } from "../models/indicator-plan.model";
import { Materie } from "../models/materie.model";
import { PlanInvatamantTableData } from "../models/plan-invatamant-table-data.model";
import { PlanInvatamant } from "../models/plan-invatamant.model";
import { useAxiosClient } from "./AxiosClient";

export const GetMaterii = (queryParams: string = '') => {
    return useAxiosClient().get('materie' + queryParams);
}

export const GetMateriiWithFilters = (filters: {filterName: string, filterValue: string}[]) => {
    return GetMaterii(BuildQueryParamsFromFilters(filters));
}

export const GetGrupari = (queryParams: string = '') => {
    return useAxiosClient().get('grupare' + queryParams);
}

export const GetGrupariWithFilters = (filters: {filterName: string, filterValue: string}[]) => {
    return GetGrupari(BuildQueryParamsFromFilters(filters));
}

export const GetPlanuriInvatamant = () => {
    return useAxiosClient().get('plan-invatamant');
}

export const GetPlanInvatamant = (id: string) => {
    return useAxiosClient().get('plan-invatamant/' + id);
}

export const GetIndicatori = () => {
    return useAxiosClient().get('plan-invatamant/indici');
}

export const GetDomeniiFundamentale = (queryParams: string = '') => {
    return useAxiosClient().get('domeniu-fundamental' + queryParams);
}

export const GetDomeniiFundamentaleWithFilters = (filters: {filterName: string, filterValue: string}[]) => {
    return GetDomeniiFundamentale(BuildQueryParamsFromFilters(filters));
}

export const GetRamuriStiinta = (queryParams: string = '') => {
    return useAxiosClient().get('ramura-stiinta' + queryParams);
}

export const GetRamuriStiintaWithFilters = (filters: {filterName: string, filterValue: string}[]) => {
    return GetRamuriStiinta(BuildQueryParamsFromFilters(filters));
}

export const GetDomenii = (queryParams: string = '') => {
    return useAxiosClient().get('domeniu' + queryParams);
}

export const GetDomeniiWithFilters = (filters: {filterName: string, filterValue: string}[]) => {
    return GetDomenii(BuildQueryParamsFromFilters(filters));
}

export const GetSpecializari = (queryParams: string = '') => {
    return useAxiosClient().get('specializare' + queryParams);
}

export const GetSpecializariWithFilters = (filters: {filterName: string, filterValue: string}[]) => {
    return GetSpecializari(BuildQueryParamsFromFilters(filters));
}

export const GetValidationRules = (modelName: string) => {
    return useAxiosClient().get(`validation/${modelName}`);
}

export const GetUniversitati = (queryParams: string = '') => {
    return useAxiosClient().get('universitate' + queryParams);
}

export const GetUniversitatiWithFilters = (filters: {filterName: string, filterValue: string}[]) => {
    return GetUniversitati(BuildQueryParamsFromFilters(filters));
}

export const GetFacultati = (queryParams: string = '') => {
    return useAxiosClient().get('facultate' + queryParams);
}

export const GetFacultatiWithFilters = (filters: {filterName: string, filterValue: string}[]) => {
    return GetFacultati(BuildQueryParamsFromFilters(filters));
}

export const GetLocuriGeografice = (queryParams: string = '') => {
    return useAxiosClient().get('loc-geografic' + queryParams);
}

export const GetLocuriGeograficeWithFilters = (filters: {filterName: string, filterValue: string}[]) => {
    return GetLocuriGeografice(BuildQueryParamsFromFilters(filters));
}

export const GetCicluriStudiu = (queryParams: string = '') => {
    return useAxiosClient().get('ciclu-studiu' + queryParams);
}

export const GetCicluriStudiuWithFilters = (filters: {filterName: string, filterValue: string}[]) => {
    return GetCicluriStudiu(BuildQueryParamsFromFilters(filters));
}

export const HandleChanges = (modifiedEntries: IModel[], deletedEntries: string[], modelPath: string) => {
    return useAxiosClient().put(modelPath, {modifiedEntries, deletedEntries});
}

export const HandleChangesPlanInvatamant = (modifiedEntries: PlanInvatamant[], deletedEntries: string[], modelPath: string) => {
    return useAxiosClient().put(modelPath, {modifiedEntries, deletedEntries});
}

export const ValidatePlanInvatamant = (planId: string, planInvatamantTableData: PlanInvatamantTableData) => {
    return useAxiosClient().post('plan-invatamant/validate', {planId, planInvatamantTableData});
}

export const GetIndicatoriPlan = (id: string) => {
    return useAxiosClient().get(`plan-invatamant/${id}/indici`);
}

export const HandleChangesIndicatoriPlan = (indiciPlan: IndicatorPlan[], id: string) => {
    return useAxiosClient().put(`plan-invatamant/${id}/indici`, {indiciPlan});
}

export const GetValidatedPlanData = (planInvatamant: PlanInvatamant, planInvatamantTableData: PlanInvatamantTableData) => {
    return useAxiosClient().post('plan-invatamant/indici/check', {planInvatamant, planInvatamantTableData});
}

export const AddPlanInvatamant = (planInvatamant: PlanInvatamant) => {
    return useAxiosClient().post('plan-invatamant', planInvatamant);
}

export const GetPermissions = () => {
    return useAxiosClient().get(`permissions`);
}

export const BuildQueryParamsFromFilters = (filters: {filterName: string, filterValue: string}[]) => {
    let query = '';
    filters.forEach((filter) => {
        if (query === '')
            query += `?${filter.filterName}=${filter.filterValue}`;
        else
            query += `&${filter.filterName}=${filter.filterValue}`;
    });
    return query;
}