import { IPlanInvatamantEntryModel } from "../../models/generic-plan-invatamant-entry.model";
import { Grupare } from "../../models/grupare.model";
import { Materie } from "../../models/materie.model";
import ComboBox from "../Combobox";
import { DetailBox } from "./DetailBox";

interface PlanEntryProps {
  entry: IPlanInvatamantEntryModel,
  type: string,
  an: number,
  semestru: number,
  index: number,
  comboBoxOptions: { label: string, value: string }[],
  handleChange: (selected, an, semestru, idx) => void
}

export const PlanEntry = (props: PlanEntryProps) => {
  let entry: Materie = {} as Materie;

  if (props.type === "GrupareViewModel") {
    let grupare = props.entry as Grupare;
    if (grupare.materie && grupare.materie.length > 0) {
      entry = grupare.materie[0];
      entry.id = grupare.id;
      entry.nume = "Optionala din setul " + grupare.nume;
    }
  }
  else if (props.type === "MaterieViewModel") {
    entry = props.entry as Materie;
  }

  return (
    <section className="w-full">
      <div className={"flex flex-col justify-center items-center " + (props.type === "GrupareViewModel" ? "bg-purple-100" : "bg-green-50")}>
        <div className="w-full h-full">
          {/* {entry.nume} */}
          <div className="">
            <ComboBox options={[{ label: "", value: "" }, ...props.comboBoxOptions]} value={{ label: entry.nume || "", value: entry.id || "" }} onChange={v => props.handleChange(v, props.an, props.semestru, props.index)} />
          </div>
        </div> {/* aici pui dropdown-ul, in acel p sau il faci div, il aranjez eu daca nu sta 100% sau ii pui "w-full h-full" pe clasa */}
        <div className="w-full flex">
          <DetailBox value={entry.cod} tooltip="Cod" />
          <DetailBox value={entry.nrCredite} tooltip="Numar credite" />
          <DetailBox value={entry.formaEvaluare} tooltip="Forma evaluare" />
          <DetailBox value={entry.oreCurs} tooltip="Ore curs" />
          <DetailBox value={entry.oreSeminar} tooltip="Ore seminar" />
          <DetailBox value={entry.oreLaborator} tooltip="Ore laborator" />
          <DetailBox value={entry.oreProiect} tooltip="Ore proiect" />
          <DetailBox value={entry.orePractica} tooltip="Ore practica" />
          <DetailBox value={entry.categorieFormativa} tooltip="Categorie formativa" />
          <DetailBox value={entry.oreIndividuale} tooltip="Ore individuale" />
        </div>
      </div>
    </section>
  );
}