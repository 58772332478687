import { clsx } from "clsx";
import { DrawerLink } from "./DrawerLink";
import { useAuth } from "react-oidc-context";

const openClassNames = {
  right: "translate-x-0",
  left: "translate-x-0",
  top: "translate-y-0",
  bottom: "translate-y-0"
};

const closeClassNames = {
  right: "translate-x-full",
  left: "-translate-x-full",
  top: "-translate-y-full",
  bottom: "translate-y-full"
};

const classNames = {
  right: "inset-y-0 right-0",
  left: "inset-y-0 left-0",
  top: "inset-x-0 top-0",
  bottom: "inset-x-0 bottom-0"
};

const Drawer = ({ open, setOpen, children, side = "left" }) => {
  const auth = useAuth();
  return (
    <div
      id={`dialog-${side}`}
      className="relative z-10"
      aria-labelledby="slide-over"
      role="dialog"
      aria-modal="true"
      onClick={() => setOpen(!open)}
    >
      <div
        className={clsx(
          "fixed inset-0 bg-gray-500 bg-opacity-75 transition-all",
          {
            "opacity-100 duration-500 ease-in-out visible": open
          },
          { "opacity-0 duration-500 ease-in-out invisible": !open }
        )}
      ></div>
      <div className={clsx({ "fixed inset-0 overflow-hidden": open })}>
        <div className="absolute inset-0 overflow-hidden">
          <div
            className={clsx(
              "pointer-events-none fixed max-w-full",
              classNames[side]
            )}
          >
            <div
              className={clsx(
                "pointer-events-auto relative w-full h-full transform transition ease-in-out duration-500",
                { [closeClassNames[side]]: !open },
                { [openClassNames[side]]: open }
              )}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <div className="w-64 h-screen px-3 py-4 shadow-xl upt-blue-bg flex flex-col justify-between">
                <div className="w-full">
                  <p className="text-lg font-semibold tracking-widest uppercase rounded-lg focus:outline-none focus:shadow-outline no-underline text-white">Gisc</p>
                  <hr className="h-px my-1 bg-gray-400 border-0"></hr>
                  <div
                    className={clsx(
                      " overflow-y-auto"
                    )}
                    onClick={(event) => {
                      setOpen(!open);
                    }}
                  >
                    {children}
                  </div>
                </div>
                <div className="w-full text-white">
                  <hr className="h-px my-3 bg-gray-400 border-0"></hr>
                  Salut <b>{auth?.user?.profile?.given_name}</b>,
                  <DrawerLink
                    title="Contul meu"
                    link="https://idp.upt.ro"
                    icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                      <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
                    </svg>} >
                  </DrawerLink>
                  <button className="flex items-center p-2 text-white rounded-lg hover:bg-indigo-600 group transition-colors duration-200 ease-in mt-2">
                    <div className="flex-shrink-0 w-5 h-5 transition duration-75 font-semibold">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
                      </svg>
                    </div>
                    <span className="flex-1 ms-3 whitespace-nowrap text-sm font-medium w-full" onClick={() => auth.signoutRedirect()}>Delogare</span>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
