import { Button } from "@mui/material";
import { YearTable } from "./YearTable";
import AddIcon from '@mui/icons-material/Add';
import { IPlanInvatamantEntryModel } from "../../models/generic-plan-invatamant-entry.model";
import { useState } from "react";
import { PlanSummaryEntryModel } from "../../models/plan-summary-entry.model";

interface YearPairTableProps {
  year1: { intrari: { intrare: IPlanInvatamantEntryModel, tip: string }[], summary: PlanSummaryEntryModel[] }[],
  year2?: { intrari: { intrare: IPlanInvatamantEntryModel, tip: string }[], summary: PlanSummaryEntryModel[] }[],
  index: number,
  materii,
  grupari,
  currentYear: number,
  handleAddButton: (index: number) => void
  handleChange: (selected, an, semestru, idx) => void
}

export const YearPairTable = (props: YearPairTableProps) => {

  return (
    <>
      <div className="flex w-full overflow-x-auto rounded-lg">
        <YearTable key={2 * props.index} yearData={props.year1} index={2 * props.index} materii={props.materii} grupari={props.grupari} currentYear={props.currentYear} handleChange={props.handleChange} />
        {
          props.year2 ?
            <YearTable key={2 * props.index + 1} yearData={props.year2} index={2 * props.index + 1} materii={props.materii} grupari={props.grupari} currentYear={props.currentYear + 1} handleChange={props.handleChange} />
            :
            <></>
        }

      </div>
      <div className="w-full flex shadow mb-20 bg-indigo-100">
        <Button startIcon={<AddIcon />} onClick={e => props.handleAddButton(props.index)} style={{ width: "100%" }}>Adauga rand pentru o materie sau o grupare</Button>
      </div>
    </>
  );
}