import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { IndicatorPlanEntry } from "./IndicatorPlanEntry";
import { IndicatorPlan } from "../../models/indicator-plan.model";
import { Indicator } from "../../models/indicator.model";

interface IndicatorTableProps {
  indicatoriPlan: IndicatorPlan[],
  indicatori: Indicator[]
  handleChange: (type: string, indicator: IndicatorPlan, value) => void
}

let defaultIndicatorPlanObject: IndicatorPlan = {
  indice: {
    id: "",
    nume: "",
  },
};

export const IndicatorTable = (props: IndicatorTableProps) => {
  let { indicatoriPlan } = props;

  return (
    <section className="w-full">
      <div className="flex justify-center items-center p-2 bg-indigo-800 border-x border-gray-400">
        <p className="text-white font-semibold">Indicatori selectati</p>
      </div>
      <div className="flex w-full flex-col">
        {
          indicatoriPlan.length > 0 ?
            indicatoriPlan.map((indicator) => {
              return <IndicatorPlanEntry
                entry={indicator}
                comboBoxOptions={[...props.indicatori.filter(e => !props.indicatoriPlan.map(i => i.indice.slug).includes(e.slug)).map(i => ({ label: i.nume, value: (i.slug || "") })), {label: indicator.indice.nume, value: (indicator.indice.slug || "")}]}
                handleChange={(type, indicator, value) => props.handleChange(type, indicator, value)}
                key={indicator.id}
              />;
            })
            :
            <></>
        }
        <IndicatorPlanEntry
          entry={structuredClone(defaultIndicatorPlanObject)}
          comboBoxOptions={props.indicatori.filter(e => !props.indicatoriPlan.map(i => i.indice.slug).includes(e.slug)).map(i => ({ label: i.nume, value: i.slug }))}
          handleChange={(type, indicator, value) => props.handleChange(type, indicator, value)}
          key={0}
        />
      </div>
      {/* <div className="w-full flex shadow mb-20 bg-indigo-100">
        <Button startIcon={<AddIcon />} onClick={e => props.handleAddButton(props.index)} style={{ width: "100%" }}>Adauga indicator</Button>
      </div> */}

    </section>
  );
}