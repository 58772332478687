import { useContext, useState } from "react";
import { GiscModal } from "../Modal"
import { ToastAlert } from "../ToastAlert";
import { AddPlanInvatamant } from "../../api/ApiService";
import ComboBox from "../Combobox";
import { PlanContext } from "../../store/plan-context";
import { Specializare } from "../../models/specializare.model";
import { Domeniu } from "../../models/domeniu.model";

interface AddPlanModalProps {
    addPlanModalOpen: boolean;
    onClose: () => void;
}

export const AddPlanModal = (props: AddPlanModalProps) => {
    const { specializari, domenii } = useContext(PlanContext);

    const [domeniu, setDomeniu] = useState<Domeniu>({ id: "", numeDl: "" });
    const [specializare, setSpecializare] = useState<Specializare>({ id: "", nume: "" });
    const [anStudiu, setAnStudiu] = useState("");
    const [anUniversitar, setAnUniversitar] = useState("");
    const [nume, setNume] = useState("");
    const [toastInfo, setToastInfo] = useState<{ message: string, show: boolean, type: "success" | "error" | "warning" | "info" }>({ message: '', type: 'info', show: false });

    const domeniiSelectOptions = domenii.filter(e => specializare.id === "" || e.id === specializare.domId).map(d => ({ label: d.numeDl, value: d.id }));
    const specializariSelectOptions = specializari.filter(e => domeniu.id === "" || e.domId === domeniu.id).map(d => ({ label: d.nume, value: d.id }));

    const onAddPlan = () => {
        AddPlanInvatamant({
            domId: domeniu?.id, specId: specializare?.id, anStudiu, anUniversitar, nume, validated: "Nevalidat"
        })
            .then(() => {
                setToastInfo({ message: 'Plan adăugat cu succes', type: 'success', show: true });
                props.onClose();
            })
            .catch(() => {
                setToastInfo({ message: 'Ceva nu a mers bine', type: 'error', show: true });
            });
    }

    return <><GiscModal isOpen={props.addPlanModalOpen} title="Adaugă plan de învațământ" onClose={() => props.onClose()} onConfirmClick={() => onAddPlan()} confirmButtonTitle="Adaugă plan">
        <form>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                    {/* replace here with dropdown */}
                    <label className="block mb-2 text-sm font-medium text-gray-900">Domeniu</label>
                    <div className="">
                        <ComboBox options={[{ label: "", value: "" }, ...domeniiSelectOptions]} value={{ label: domeniu.numeDl, value: domeniu.id || "" }} onChange={v => setDomeniu(domenii.find(d => d.id === v?.value) || { id: "", numeDl: "" })} />
                    </div>
                </div>
                <div>
                    {/* replace here with dropdown */}
                    <label className="block mb-2 text-sm font-medium text-gray-900">Specializare</label>
                    <div className="">
                        <ComboBox options={[{ label: "", value: "" }, ...specializariSelectOptions]} value={{ label: specializare.nume, value: specializare.id || "" }} onChange={v => setSpecializare(specializari.find(d => d.id === v?.value) || { id: "", nume: "" })} />
                    </div>
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">An studiu</label>
                    <input type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="2023-2027" onChange={e => setAnStudiu(e.target.value)} />
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">An universitar</label>
                    <input type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="2023-2027" onChange={e => setAnUniversitar(e.target.value)} />
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900">Nume</label>
                    <input type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={e => setNume(e.target.value)} />
                </div>
            </div>
        </form>
    </GiscModal>
        {toastInfo.show ? <ToastAlert message={toastInfo.message} type={toastInfo.type} onClose={() => setToastInfo({ ...toastInfo, show: false })} /> : <></>}

    </>
}
