import { RamuraStiinta } from "../models/ramura-stiinta.model";
import { SheetPage } from "./SheetPage";
import { ValidationRule } from "../models/validation-rule.model";
import { GetRamuriStiintaWithFilters, GetDomeniiFundamentale, GetValidationRules, HandleChanges } from "../api/ApiService";
import { useEffect, useState } from "react";
import { Column, SelectColumn, textEditor } from "react-data-grid";
import { DropdownOption, RenderDropdown } from "../components/renderers/DropdownRenderer";
import { HeaderCellRenderer } from "../components/renderers/HeaderCellRenderer";
import { DomeniuFundamental } from "../models/domeniu-fundamental.model";
import { PermissionType } from "../store/permission-context";

export const RamuriStiintaPage = () => {
  const [ramuriStiinta, setRamuriStiinta] = useState<RamuraStiinta[]>([]);
  const [domeniiFundamentale, setDomeniiFundamentale] = useState<DomeniuFundamental[]>([]);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>([]);
  const [filters, setFilters] = useState<{ filterName: string, filterValue: string }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetRequiredData().then(x => {
      setRamuriStiinta(x.ramuriStiinta);
      setDomeniiFundamentale(x.domeniiFundamentale);
      setValidationRules(x.validationRules);
      setLoading(false);
    })
  }, [filters]);

  const GetRequiredData = async (): Promise<{ domeniiFundamentale: DomeniuFundamental[], ramuriStiinta: RamuraStiinta[], validationRules: ValidationRule[] }> => {
    let ramuriStiinta = (await GetRamuriStiintaWithFilters(filters)).data;

    let domeniiFundamentale = (await GetDomeniiFundamentale()).data;

    let validationRules = [];
    try {
      validationRules = (await GetValidationRules('RamuraStiinta')).data;
    } catch (error) {
      console.log('Validation rules not found');
    }

    return { domeniiFundamentale, ramuriStiinta, validationRules }
  }

  const applyFilter = (filterName: string, filterValue: string) => {
    setFilters(oldFilters => [...oldFilters.filter(f => f.filterName !== filterName && f.filterValue !== ''), { filterName, filterValue }]);
  }

  const getColumns = (): Column<RamuraStiinta>[] => {
    return [
      SelectColumn,
      {
        key: 'cod',
        name: 'Cod',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ' '}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'nume',
        name: 'Nume',
        renderEditCell: textEditor,
        resizable: true,
        draggable: false,
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === props.column.key)?.filterValue || ' '}
            applyFilter={val => applyFilter(props.column.key, val)}
          />)
      },
      {
        key: 'domeniuFundamental',
        name: 'Domeniu fundamental',
        renderEditCell: (p) => {
          return <RenderDropdown {...p} options={domeniiFundamentale.map((d) => ({ label: d.nume, value: d.id }))} items={domeniiFundamentale} handleChange={handleDomeniuFundamentalDropdownChange} foreignKeyItemKey='domFundId' />
        },
        renderCell: (p) => (<>{p.row.domeniuFundamental?.nume}</>),
        renderHeaderCell: (props) => (
          <HeaderCellRenderer
            {...props}
            value={filters.find(f => f.filterName === 'domeniuFundamental')?.filterValue || ' '}
            applyFilter={val => applyFilter('domeniuFundamental', val)}
          />)
      }
    ]
  };

  const handleDomeniuFundamentalDropdownChange = (row: RamuraStiinta, columnKey: string, selected: DropdownOption | null, items: DomeniuFundamental[]) => {
    if (selected) {
      const found = items.find(i => i.id === selected.value);
      if (!found) {
        row['domFundId'] = '';
        delete row['domeniuFundamental'];
      }
      else {
        row['domFundId'] = found.id;
        row['domeniuFundamental'] = found;
      }
    }
    else {
      row['domFundId'] = '';
      delete row['domeniuFundamental'];
    }

    return row;
  }

  const rowsToString = (rows: RamuraStiinta[], columnsOrder: readonly number[]): string => {
    var copiedString = '';
    const columnsKeys = getColumns().map(c => c.key).slice(1);

    rows.forEach(row => {
      for (let idx = 0; idx < columnsKeys.length; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        var separator = idx == columnsKeys.length - 1 ? '\r\n' : '\t';

        switch (columnKey) {
          case 'domeniuFundamental':
            var domeniuFundamental = domeniiFundamentale.find(e => e.id == row.domeniuFundamental?.id);
            copiedString += domeniuFundamental?.nume;
            break;

          default:
            copiedString += row[columnKey as keyof RamuraStiinta];
            break;
        }
        copiedString += separator
      }
    })

    return copiedString;
  }

  const stringToRows = (splitText: string[][], columnsOrder: readonly number[]): RamuraStiinta[] => {
    var rows: RamuraStiinta[] = [];
    const columnsKeys = getColumns().map(c => c.key).slice(1);

    splitText.forEach(textRow => {
      var row: RamuraStiinta = structuredClone(defaultObject) as RamuraStiinta;
      row.id = crypto.randomUUID();
      for (let idx = 0; idx < columnsKeys.length; idx++) {
        var columnKey = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'domeniuFundamental':
            var domeniuFundamental = domeniiFundamentale.find(e => e.nume == textRow[idx]);
            row['domFundId'] = domeniuFundamental?.id ?? "";
            row['domeniuFundamental'] = domeniuFundamental;
            break;
          default:
            row[columnKey] = textRow[idx];
            break;
        }
      }
      rows.push(row);
    })

    return rows;
  }

  const addPastedRows = (currentRows: RamuraStiinta[], rowIdx: number, columnIdx: number, columnsOrder: readonly number[], splitText: string[][]): { rows: RamuraStiinta[], indexes: number[] } => {
    var initialRowCount = currentRows.length;
    var currentSheetRowIdx = rowIdx;
    const columnsKeys = columns.map(c => c.key).slice(1);

    const transformedClipboardRows = stringToRows(splitText, columnsOrder);

    var newSheetRows = [...currentRows];
    splitText.forEach((clipboardRow, clipboardRowIdx) => {
      var clipboardColumnIdx = 0;

      var row = structuredClone(defaultObject) as RamuraStiinta;
      row.id = crypto.randomUUID();
      if (currentSheetRowIdx < initialRowCount - 1) {
        row = currentRows[currentSheetRowIdx] as RamuraStiinta;
      }

      for (let idx = columnIdx - 1; idx < columnsOrder.length; idx++) {
        if (clipboardColumnIdx == clipboardRow.length) { //if clipboard row has reached the last column
          break;
        }

        var columnKey: string = columnsKeys[columnsOrder[idx]];
        switch (columnKey) {
          case 'domeniuFundamental':
            row['domFundId'] = transformedClipboardRows[clipboardRowIdx]['domeniuFundamental']?.id || '';
            break;
          default:
            break;
        }
        row[columnKey] = transformedClipboardRows[clipboardRowIdx][columnKey];
      }

      if (currentSheetRowIdx <= initialRowCount) { //if we have not reached the end of the sheet, we edit the current row, else add a new one
        newSheetRows[currentSheetRowIdx++] = row;
      }
      else {
        newSheetRows.push(row);
      }
    })

    const indexes = [...Array(transformedClipboardRows.length).keys()].map(i => i + rowIdx);

    return { rows: newSheetRows, indexes: indexes };
  }

  // to detect empty objects or deleted objects
  const hasEmptyProperties = (row: RamuraStiinta): boolean => {
    if (row.domFundId !== '' || !!row.domFundId) return false;
    if (row.nume !== '' || !!row.nume) return false;
    if (row.cod !== '' || !!row.cod) return false;
    return true;
  }

  const refreshTable = (): void => {
    setFilters([...filters]);
  }

  const columns = getColumns();
  let defaultObject: RamuraStiinta = { id: '', nume: '', domFundId: '', cod: '' };

  if (loading) {
    return <div>Loading...</div>;
  }

  return <SheetPage
    requiredEditPermission={PermissionType.RamuraStiintaWrite}
    sheetColumns={columns}
    title={'Ramuri de știință'}
    hasEmptyProperties={e => hasEmptyProperties(e as RamuraStiinta)}
    initialEntries={ramuriStiinta}
    validationRules={validationRules}
    onSave={(modifiedEntries, deletedEntries) => HandleChanges(modifiedEntries, deletedEntries, 'ramura-stiinta')}
    rowsToString={(rows, columnsOrder) => rowsToString(rows as RamuraStiinta[], columnsOrder)}
    stringToRows={(splitText, columnsOrder) => stringToRows(splitText, columnsOrder)}
    addPastedRows={(currentRows, rowIdx, columnIdx, columnsOrder, splitText) => addPastedRows(currentRows, rowIdx, columnIdx, columnsOrder, splitText)}
    deleteCell={(currentRows, rowIdx, columnKey) => deleteCell(currentRows, rowIdx, columnKey)}
    defaultObject={defaultObject}
    refreshTable={refreshTable} />
}