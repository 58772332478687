import axios, { AxiosInstance } from "axios";
import { User } from "oidc-client-ts";

function getUser() {
  const oidcStorage = sessionStorage.getItem(`oidc.user:https://idp.upt.ro/application/o/gisc/:EXEh66DVi912HZpaWtnUspgh65uCuPwp7FDpGBY7`)
  if (!oidcStorage) {
      return null;
  }

  return User.fromStorageString(oidcStorage);
}


export const useAxiosClient = () : AxiosInstance => {
  let user = getUser();
  let client = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? "http://localhost:5077/api" : "/api",
    timeout: 30000
  });
  client.interceptors.request.use(config => {
    let authToken = user?.access_token;
    if (authToken)
      config.headers.Authorization = `Bearer ${authToken}`;
    return config;
  });
  return client;
}

