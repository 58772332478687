
import { PlanSummaryEntryModel } from "../../models/plan-summary-entry.model";
import { DetailBox } from "./DetailBox";

interface SemesterSummaryProps {
    summary: PlanSummaryEntryModel[]
}

export const SemesterSummary = (props: SemesterSummaryProps) => {
    const { summary } = props;

    const oreSemestruError = summary.find(e => e.slug === "ore-semestru")?.error;
    const crediteSemestruError = summary.find(e => e.slug === "credite-semestru")?.error;
    const oreSaptamanaError = summary.find(e => e.slug === "ore-saptamana")?.error;

    const errorClass = "bg-red-300";

    return (
        <div className="flex w-full flex-col bg-cyan-50 border-t-4 border-indigo-500">

            {/*row 1*/}
            <div className="w-full flex">
                <section className="h-auto flex border-x border-gray-400 bg-pink-100">
                    <section className=" h-auto flex flex-col justify-center">
                        <p className="px-1">total/</p>
                        <p className="px-1">sem.</p>
                    </section>
                </section>
                <section className="w-full flex">
                    <section className="w-full flex flex-col">
                        <div className="w-full flex justify-between px-3 py-1 border-gray-400 border-e">
                            <p>ore:</p>
                            <p className="font-semibold">{summary.find(e => e.slug === "ore-semestru")?.value}</p>
                        </div>
                        <div className={"flex px-3 py-1 border-gray-400 border-e border-y " + (crediteSemestruError !== "" ? errorClass : "")}>
                            {
                                summary.find(e => e.slug === "credite-semestru")?.error !== "" ?
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 mr-1 text-white">bg-red-300
                                        <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8-5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 10 5Zm0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" clipRule="evenodd" />
                                    </svg>
                                    :
                                    <></>
                            }
                            <div className="w-full flex justify-between">
                                <p>credite:</p>
                                <p className="font-semibold">{summary.find(e => e.slug === "credite-semestru")?.value}</p>
                            </div>
                        </div>
                    </section>
                    <section className="w-full flex flex-col">
                        <div className="w-full flex justify-between px-3 py-1 border-gray-400 border-e">
                            <p>VPI:</p>
                            <p className="font-semibold">{summary.find(e => e.slug === "ore-individuale-total")?.value}</p>
                        </div>
                        <div className="w-full flex justify-between px-3 py-1 border-gray-400 border-e border-y">
                            <p>evaluări:</p>
                            <p className="font-semibold">{summary.find(e => e.slug === "evaluari-examen")?.value || 0}E,{summary.find(e => e.slug === "evaluari-distribuita")?.value || 0}D,{summary.find(e => e.slug === "evaluari-colocviu")?.value || 0}C</p>
                        </div>

                    </section>
                </section>
            </div>


            {/*row 2*/}
            <div className="w-full flex">
                <section className="h-auto flex border-gray-400 border bg-pink-100">
                    <section className=" h-auto flex flex-col justify-center">
                        <p className="px-1">total/</p>
                        <p className="px-1">sapt.</p>
                    </section>
                </section>
                <section className="w-full flex flex-col">
                    <div className={"flex px-3 py-1 border-gray-400 border-e border-y " + (oreSaptamanaError !== "" ? errorClass : "")}>
                        {
                            summary.find(e => e.slug === "ore-saptamana")?.error !== "" ?
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 mr-1 text-white">bg-red-300
                                    <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8-5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 10 5Zm0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" clipRule="evenodd" />
                                </svg>
                                :
                                <></>
                        }
                        <div className="flex w-full justify-between">

                            <p>ore:</p>
                            <p className="font-semibold">{summary.find(e => e.slug === "ore-saptamana")?.value}</p>
                        </div>
                    </div>
                    <div className="w-full flex justify-between px-3 border-gray-400 border-e border-y">
                        <p>din care:</p>
                        <p className="font-semibold"></p>
                    </div>
                </section>
                <section className="w-full flex">
                    <div className="w-full flex flex-col justify-around">
                        <p className="min-h-8"></p>
                        <div className="w-full flex">
                            <DetailBox value={summary.find(e => e.slug === "ore-curs-saptamana")?.value || 0} />
                            <DetailBox value={summary.find(e => e.slug === "ore-seminar-saptamana")?.value || 0} />
                            <DetailBox value={summary.find(e => e.slug === "ore-laborator-saptamana")?.value || 0} />
                            <DetailBox value={summary.find(e => e.slug === "ore-proiect-saptamana")?.value || 0} />
                            <DetailBox value="(c,s,l,p)" />
                        </div>
                    </div>
                </section>
            </div>

        </div>

    );
}