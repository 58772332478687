import { NavLink } from "react-router-dom";

export interface GenericTableHeader {
    label: string;
    key: string;
    subHeaders?: GenericTableHeader[];
}

export interface GenericTableProps {
    headers: GenericTableHeader[];
    data: any[];
    showViewButton?: boolean;
    viewButtonLabel?: string;
}


export const GenericReadonlyTable = (props: GenericTableProps) => {
    let hasSubHeaders: boolean = false;
    let dataColumnsKeys: string[] = [];

    return <div className="overflow-x-auto">
                <table className="text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-white uppercase">
                        <tr className="upt-blue-bg">
                            {
                                props.headers.map(header => {
                                    let colSize = 1;
                                    if (header.subHeaders && header.subHeaders.length > 0) {
                                        hasSubHeaders = true;
                                        dataColumnsKeys = [...dataColumnsKeys, ...header.subHeaders.map(subHeader => subHeader.key)];
                                        colSize = header.subHeaders.length;
                                    }
                                    else {
                                        dataColumnsKeys = [...dataColumnsKeys, header.key];
                                    }
                                    return <th key={header.key + header.label} colSpan={colSize} scope="col" className="px-4 py-2 border text-center">
                                        {header.label}
                                    </th>

                                })
                            }
                            {props.showViewButton ? <th key="view-button" colSpan={1} scope="col" className="px-4 py-1 border">Vizualizare</th> : <></> }
                        </tr>
                        {
                            hasSubHeaders ? <tr className="bg-[#6A6CD8]">
                                {
                                    props.headers.map(header => {
                                        if (header.subHeaders && header.subHeaders.length > 0) {
                                            return header.subHeaders.map(subHeader => {
                                                return <th key={subHeader.key} colSpan={1} scope="col" className="px-4 py-1 border">
                                                    {subHeader.label}
                                                </th>
                                            })
                                        }
                                        return <th colSpan={1} scope="col" className="px-4 py-1 border">
                                            </th>;
                                    })
                                }
                            </tr> : <></>
                        }
                        
                    </thead>
                    <tbody>
                        {
                            props.data.map((row, index) => {
                                return <tr className={index % 2 === 0 ? "bg-white" : "bg-violet-100"}>
                                    {
                                        dataColumnsKeys.map((key, index) => {
                                            return <td key={key} className="px-4 py-2">
                                                {row[key]}
                                            </td>
                                        })
                                    }
                                    {props.showViewButton ? <td><NavLink className="px-4 py-2" to={row.id}>{props.viewButtonLabel}</NavLink></td> : <></> }
                                </tr>
                            })
                        }
                        
                    </tbody>
                </table>
        </div>
}
